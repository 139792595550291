@import "../_color_palettes.scss";

.superscriber-popup {
  width: 300px;
  height: 450px;

  .title {
    padding-left: 24px;
    height: 60px;
    background-color: $palette-alt-blue;
    color: $palette-white;
    font-size: 24px;
  }

  .inner {
    padding: 0;
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    -webkit-flex: 1;
    flex: 1;
    text-align: left;

    .form {

      div.content_date{
        box-sizing: border-box;
        text-align: left;
        margin: 10px 8px;
        padding: 0 20px;
        width: 100%;

        .item-title {
          color: $palette-slate;
          margin: 12px 0 0 0;
        }

        .custom-date-picker {
          width: 130px;
          margin-right: 10px;
          label {
            width: 100%;
            background-position: left top;
            padding-left: 18px;
          }
        }
      }

      div.content_plan{
        box-sizing: border-box;
        text-align: left;
        margin: 10px 8px;
        padding: 0 20px;
        width: 100%;

        .item-title {
          color: $palette-slate;
          margin: 12px 0 0 0;
        }

        .radio_button{
          margin: 6px 0;
          width: 16px;
          height: 16px;
          float: left;

          input[type="radio"]:checked + .radio
          {
            color: #ffffff;
            background-color: #31A9EE;
          }
        }

        .label_undisabled {
          margin: 8px 15px 8px 4px;
          float: left;
          font-size: 14px;
          color: $palette-slate;
        }

        .label_disabled {
          margin: 8px 15px 8px 4px;
          float: left;
          font-size: 14px;
          color: grey;
        }
      }
    }
  }
}

> .buttons {
  margin: 0;
  padding: 16px;
  height: 68px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;

  button {
    margin: 0 8px;
    width: 120px;
    height: 40px;
    border: none;
    border-radius: 4px;
    font-size: 13px;
    box-sizing: border-box;
  }
}
