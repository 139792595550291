.member-menu {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: left; }
  .member-menu div.member-menu-left {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 175px;
    height: 100%;
    background-color: #07347B;
    color: #FFFFFF; }
    .member-menu div.member-menu-left div.member-menu-header {
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-top: 20px; }
      .member-menu div.member-menu-left div.member-menu-header span {
        padding-left: 13px; }
    .member-menu div.member-menu-left ul {
      display: flex;
      flex-direction: column; }
    .member-menu div.member-menu-left li {
      height: 40px;
      cursor: pointer;
      position: relative;
      padding-left: 20px; }
      .member-menu div.member-menu-left li.disabled {
        background-color: #192D5A;
        pointer-events: none; }
      .member-menu div.member-menu-left li span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
  .member-menu div.member-menu-right {
    -webkit-flex: 1;
    flex: 1;
    height: 100%; }
