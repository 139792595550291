@charset "UTF-8";
.my-group-picker {
  display: flex;
  position: relative; }
  .my-group-picker > button {
    padding: 4px 24px;
    border-radius: 4px; }
    .my-group-picker > button.clear {
      margin-left: 8px; }
  .my-group-picker .select-box > div {
    height: 300px;
    width: 300px;
    position: absolute;
    box-sizing: border-box;
    background-color: #FFFFFF;
    box-shadow: inset 1px 0 rgba(25, 45, 90, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    z-index: 2; }
    .my-group-picker .select-box > div .options {
      display: flex;
      flex-direction: column;
      padding: 8px 20px;
      width: 100%;
      height: 190px;
      box-sizing: border-box;
      overflow-y: auto; }
      .my-group-picker .select-box > div .options > label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        font-weight: normal;
        white-space: nowrap; }
        .my-group-picker .select-box > div .options > label div.check {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          margin-right: 8px;
          box-sizing: border-box;
          background-color: #EAEBEF;
          border-radius: 3px;
          content: ''; }
        .my-group-picker .select-box > div .options > label:hover div.check::before {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
        .my-group-picker .select-box > div .options > label:active div.check {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
      .my-group-picker .select-box > div .options input[type="checkbox"] {
        display: none; }
        .my-group-picker .select-box > div .options input[type="checkbox"]:checked + div.check {
          background-color: #FFC800; }
          .my-group-picker .select-box > div .options input[type="checkbox"]:checked + div.check::before {
            color: #192D5A;
            content: '✔'; }
    .my-group-picker .select-box > div .search {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      box-sizing: border-box;
      padding: 8px;
      border-bottom: solid 1px #E2E3E8; }
      .my-group-picker .select-box > div .search div {
        padding: 8px 0 0 0;
        height: 20px;
        font-size: 12px; }
    .my-group-picker .select-box > div .operations {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      box-shadow: inset 0 1px #E2E3E8;
      height: 50px;
      box-sizing: border-box; }
      .my-group-picker .select-box > div .operations button {
        width: 100px;
        height: 30px; }
  .my-group-picker label {
    flex-shrink: 0;
    padding: 2px 0;
    text-align: left;
    width: 100%;
    line-height: 1em; }
  .my-group-picker input, .my-group-picker select, .my-group-picker option {
    padding-left: 10px;
    height: 24px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    font-size: 1.0em;
    box-sizing: border-box; }
