.help-menu {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: left; }
  .help-menu .help-menu-left {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    width: 250px;
    height: 100%;
    background-color: #07347B;
    color: #FFFFFF; }
    .help-menu .help-menu-left li {
      height: 40px;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      position: relative;
      padding-left: 20px; }
      .help-menu .help-menu-left li.disabled {
        background-color: #192D5A;
        pointer-events: none; }
      .help-menu .help-menu-left li span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
  .help-menu .help-menu-header {
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-top: 20px; }
    .help-menu .help-menu-header span {
      padding-left: 13px; }
  .help-menu ul {
    display: flex;
    flex-direction: column; }
  .help-menu .member-menu-right {
    -webkit-flex: 1;
    flex: 1; }
  .help-menu .help-content {
    margin-top: 25px;
    margin-left: 28px; }
    .help-menu .help-content .help-content-title {
      font-size: 20px; }
    .help-menu .help-content li {
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
      position: relative;
      padding-bottom: 23px; }
      .help-menu .help-content li.disabled {
        background-color: #192D5A;
        pointer-events: none; }
      .help-menu .help-content li span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .help-menu .help-content li a {
        display: flex;
        flex-direction: row;
        line-height: 30px; }
        .help-menu .help-content li a div {
          margin-left: 5px; }
