.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #192D5A;
  background: linear-gradient(180deg, transparent 0%, transparent 57%, #F0EFF4 57%, #F0EFF4 100%); }
  .login .login-logo {
    width: 248px;
    height: 46px; }
  .login .login-left {
    width: 400px;
    height: 500px; }
    .login .login-left .login-left-top {
      margin-bottom: 20px; }
    .login .login-left .login-left-bottom {
      box-shadow: 2px 2px 2px 4px rgba(25, 45, 90, 0.15);
      background-color: white; }
      .login .login-left .login-left-bottom .login-login {
        font-size: 20px;
        padding: 40px 0 32px 0; }
    .login .login-left form {
      margin: 20px auto;
      width: 90%;
      font-size: 0.8em; }
      .login .login-left form > div {
        display: flex;
        margin: 6px 0 0 0; }
    .login .login-left div a {
      text-align: center;
      text-decoration: none;
      padding: 20px 0 39px 0;
      font-size: 12px; }
    .login .login-left form input {
      width: 100%;
      height: 20px;
      padding: 5px 10px;
      border-radius: 3px;
      border: 1.5px solid #eeeeee; }
      .login .login-left form input:hover {
        border-color: #a7b4cf; }
      .login .login-left form input[type=submit] {
        margin: 20px auto 0 auto;
        height: 40px;
        font-size: 14px;
        border-style: none;
        border-radius: 4px; }
  .login .login-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000; }
  .login h1 {
    font-size: 1.4em;
    color: #224488; }
