.meeting-statistics-score {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F4F5F7; }
  .meeting-statistics-score .score-detail {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    font-size: 13px; }
    .meeting-statistics-score .score-detail a {
      text-decoration: none; }
    .meeting-statistics-score .score-detail .score-row-button:hover {
      background-color: #EAEBEF; }
    .meeting-statistics-score .score-detail .score-row {
      display: flex;
      flex-direction: row;
      margin: 5px 15px;
      padding: 5px 20px;
      background-color: #FFFFFF;
      color: #192D5A;
      border-radius: 3px; }
      .meeting-statistics-score .score-detail .score-row .left-column {
        width: 218px;
        text-align: left;
        border-right: 1px solid #E2E3E8; }
        .meeting-statistics-score .score-detail .score-row .left-column > div:nth-child(1) {
          margin-top: 5px; }
        .meeting-statistics-score .score-detail .score-row .left-column > div:nth-child(2) {
          font-weight: bold;
          word-break: break-all;
          margin-top: 20px; }
        .meeting-statistics-score .score-detail .score-row .left-column > div:nth-child(3) {
          margin-top: 20px; }
        .meeting-statistics-score .score-detail .score-row .left-column > div:nth-child(4) {
          margin-top: 5px;
          margin-bottom: 10px; }
        .meeting-statistics-score .score-detail .score-row .left-column button {
          padding: 10px 16px;
          margin: 0 0 14px 20px;
          height: 33px;
          font-size: 13px;
          box-sizing: border-box;
          width: 160px; }
      .meeting-statistics-score .score-detail .score-row .total-column {
        width: 100px;
        text-align: center;
        padding-bottom: 80px;
        margin-left: 26px; }
        .meeting-statistics-score .score-detail .score-row .total-column > div:first-child {
          margin: 15px 0; }
        .meeting-statistics-score .score-detail .score-row .total-column .meeting-total {
          font-size: 36px;
          color: #FFC800;
          margin: 0 auto 10px auto;
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-weight: bold; }
          .meeting-statistics-score .score-detail .score-row .total-column .meeting-total > div {
            margin-left: 2px;
            display: flex;
            flex-direction: column-reverse; }
            .meeting-statistics-score .score-detail .score-row .total-column .meeting-total > div div {
              font-size: 18px; }
        .meeting-statistics-score .score-detail .score-row .total-column .meeting-starts {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 0 auto; }
          .meeting-statistics-score .score-detail .score-row .total-column .meeting-starts > div {
            padding-left: 1px; }
      .meeting-statistics-score .score-detail .score-row .document-column {
        width: 110px;
        margin-left: 20px; }
      .meeting-statistics-score .score-detail .score-row .time-column {
        width: 110px; }
      .meeting-statistics-score .score-detail .score-row .progress-column {
        width: 110px; }
      .meeting-statistics-score .score-detail .score-row .conclusion-column {
        width: 110px; }
      .meeting-statistics-score .score-detail .score-row .next-meeting-column {
        width: 110px; }
      .meeting-statistics-score .score-detail .score-row .speaker-column {
        width: 110px;
        margin-right: 20px; }
      .meeting-statistics-score .score-detail .score-row .bar-area {
        height: 90px;
        border-bottom: solid 1px #E2E3E8;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end; }
        .meeting-statistics-score .score-detail .score-row .bar-area .percent {
          font-weight: bold;
          margin: auto auto 7px auto;
          font-size: 13px; }
        .meeting-statistics-score .score-detail .score-row .bar-area .bar {
          width: 35px;
          margin-left: auto;
          margin-right: auto;
          background-color: #FFC800;
          border-radius: 3px 3px 0 0; }
      .meeting-statistics-score .score-detail .score-row .column-title {
        margin: 10px 0;
        font-weight: bold;
        font-size: 12px; }
