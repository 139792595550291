.audio-player {
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  margin: 10px 0; }

.audio-player > .slider {
  flex: 1 1 50%; }

.audio-player > .buttons {
  flex: 0 0 142px; }

.audio-player > .simple-buttons {
  flex: 0 0 30px; }

.audio-player > .slider input {
  padding: 0;
  width: 80%; }

.audio-player .simple-buttons {
  display: flex;
  font-size: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px; }

.audio-player .buttons {
  display: flex;
  font-size: 30px;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px; }

.audio-player .close-button-area {
  display: flex;
  padding: 0 10px 0 0; }

.audio-player label,
.audio-player input,
.audio-player .buttons > div {
  cursor: pointer; }

.audio-player .buttons .continuous label {
  display: flex;
  font-size: 13px;
  align-items: center;
  color: #192D5A; }

.audio-player.hidden {
  display: none !important; }
