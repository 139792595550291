.popup {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9980;
  color: #192D5A; }
  .popup .alert-popup {
    width: 350px;
    height: 170px; }
  .popup > div {
    padding: 0;
    opacity: 1.0;
    background-color: #FFFFFF;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-radius: 4px; }
  .popup .title {
    height: 60px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .popup .inner {
    padding: 0 20px 0px 20px;
    -webkit-flex: 1;
    flex: 1;
    font-size: 13px;
    word-break: break-all;
    overflow-y: auto; }
  .popup .buttons {
    box-shadow: inset 0 1px #E2E3E8;
    padding: 0 20px 0 20px;
    height: 50px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .popup .buttons button {
      margin: 0;
      width: 100px;
      height: 30px;
      font-size: 13px;
      border-radius: 4px;
      cursor: pointer; }
  .popup form > div {
    text-align: left;
    display: flex;
    flex-direction: column; }
  .popup form input {
    margin-top: 2px;
    width: 100%;
    padding: auto 10px;
    height: 24px;
    border: 1px solid #EEEEEE;
    border-radius: 4px; }
