.popup .comment-popup {
  width: 630px;
  height: 500px; }
  .popup .comment-popup .inner {
    padding: 0 50px; }
  .popup .comment-popup .title {
    background-color: #0A46A5;
    color: #FFFFFF;
    height: 24px;
    padding: 18px 50px;
    margin-bottom: 15px; }
  .popup .comment-popup .buttons {
    margin: 0;
    padding: 20px 50px;
    height: 80px;
    box-sizing: border-box; }
  .popup .comment-popup form > div {
    padding-bottom: 20px; }
  .popup .comment-popup label {
    margin-top: 10px;
    margin-bottom: 2px;
    font-weight: bold; }
  .popup .comment-popup button {
    font-weight: bold; }
  .popup .comment-popup textarea {
    height: 64px;
    padding: 6px 10px;
    max-width: 100%;
    resize: none; }
  .popup .comment-popup input {
    padding: 6px 10px;
    width: auto; }
  .popup .comment-popup select {
    padding-left: 10px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    font-size: 1.0em;
    box-sizing: border-box;
    height: 40px;
    width: 100%; }
  .popup .comment-popup .audio-player {
    margin: 0; }
  .popup .comment-popup .errors {
    margin: 5px 0;
    text-align: center;
    font-size: 12px; }
