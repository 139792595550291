.calculation-dictionary-words {
  background-color: white;
  height: 100%; }
  .calculation-dictionary-words .words-header {
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .calculation-dictionary-words .words-header .title {
      padding: 8px 16px;
      color: #113355; }
    .calculation-dictionary-words .words-header > .title {
      font-size: 20px;
      max-width: 360px;
      padding: 8px 8px 8px 16px;
      box-sizing: border-box;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden; }
    .calculation-dictionary-words .words-header button {
      font-size: 13px;
      padding: 10px;
      font-weight: bold;
      margin-left: 15px; }
  .calculation-dictionary-words .words-list {
    overflow-y: auto;
    height: calc(100% - 29px - 8px - 8px - 29px - 56px); }
    .calculation-dictionary-words .words-list table {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      border-collapse: separate;
      border-spacing: inherit;
      font-size: 12px;
      table-layout: fixed;
      overflow: hidden; }
      .calculation-dictionary-words .words-list table thead {
        background-color: rgba(1, 81, 204, 0.15); }
        .calculation-dictionary-words .words-list table thead th {
          padding: 8px;
          text-align: center;
          color: #07347B; }
      .calculation-dictionary-words .words-list table tbody tr {
        padding: 3px;
        height: 60px;
        cursor: pointer;
        background: #FFFFFF; }
        .calculation-dictionary-words .words-list table tbody tr:hover td {
          background-color: #EAEBEF; }
      .calculation-dictionary-words .words-list table tbody td {
        box-sizing: border-box;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        background-color: #FFFFFF; }
        .calculation-dictionary-words .words-list table tbody td:first-child {
          border-left: 4px solid transparent; }
        .calculation-dictionary-words .words-list table tbody td:last-child {
          border-right: 4px solid transparent; }
      .calculation-dictionary-words .words-list table tbody td {
        padding: 8px;
        color: #192D5A; }
    .calculation-dictionary-words .words-list th.word, .calculation-dictionary-words .words-list td.word {
      text-align: left;
      padding-left: 15px; }
    .calculation-dictionary-words .words-list th.functions {
      width: 96px;
      text-align: right; }
    .calculation-dictionary-words .words-list td.functions {
      width: 96px;
      text-align: right; }
      .calculation-dictionary-words .words-list td.functions > div {
        display: flex;
        justify-content: flex-end; }
      .calculation-dictionary-words .words-list td.functions button + button {
        margin-left: 8px; }
  .calculation-dictionary-words button {
    margin-left: 3px; }
