.meeting-individual-score {
  width: 790px;
  height: calc(100vh - 100px);
  max-height: 685px; }
  .meeting-individual-score > .title {
    padding-left: 50px;
    height: 60px;
    background-color: #0A46A5;
    color: #FFFFFF;
    font-size: 24px; }
  .meeting-individual-score > .buttons {
    flex-direction: row;
    height: 80px;
    padding: 0 50px 0px 50px; }
    .meeting-individual-score > .buttons button {
      height: 40px; }
  .meeting-individual-score .body {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column; }
  .meeting-individual-score .meeting-individual-score-area {
    text-align: left;
    height: calc(100vh - 240px);
    max-height: 555px;
    overflow-y: auto; }
    .meeting-individual-score .meeting-individual-score-area .meeting-score-table {
      margin-left: 50px;
      margin-right: 50px;
      overflow-y: auto; }
