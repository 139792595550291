.dictionaries {
  box-sizing: border-box;
  font-size: 0.75em;
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 100%; }
  .dictionaries .dictionaries-left {
    width: 314px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F4F5F7;
    border-right: 1px solid #EAEBEF; }
  .dictionaries .dictionaries-right {
    flex: 1;
    -webkit-flex: 1;
    height: 100%; }
  .dictionaries .dictionaries-detail {
    overflow: auto;
    flex: 1; }
  .dictionaries .dictionaries-header {
    margin-bottom: 4px;
    box-shadow: 0 2px 4px #e2e2e4;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFFFFF; }
    .dictionaries .dictionaries-header .dictionaries-title {
      padding: 8px 16px;
      color: #192D5A;
      font-size: 16px;
      font-weight: bold;
      margin-right: auto; }
    .dictionaries .dictionaries-header > .dictionaries-title {
      padding: 6px 8px 8px 15px;
      width: 200px;
      box-sizing: border-box;
      text-align: left; }
    .dictionaries .dictionaries-header button {
      margin-right: 15px; }
  .dictionaries table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: inherit;
    font-size: 12px;
    table-layout: fixed;
    overflow: hidden; }
    .dictionaries table thead {
      background-color: #EEEEF4; }
      .dictionaries table thead th {
        padding: 8px;
        text-align: center;
        color: #444444; }
    .dictionaries table tbody tr {
      padding: 3px;
      height: 60px;
      cursor: pointer; }
      .dictionaries table tbody tr.active td {
        background-color: #E2E3E8; }
      .dictionaries table tbody tr:hover td {
        background-color: #EAEBEF; }
    .dictionaries table tbody td {
      box-sizing: border-box;
      border-bottom: 1px solid #E2E3E8;
      background: #FFFFFF; }
    .dictionaries table tbody td {
      padding: 8px;
      color: #192D5A; }
  .dictionaries th.name, .dictionaries td.name {
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    width: 145px;
    word-break: break-all; }
  .dictionaries th.num-of-words, .dictionaries td.num-of-words {
    text-align: left;
    padding-left: 0; }
    .dictionaries th.num-of-words .num-label, .dictionaries td.num-of-words .num-label {
      background-color: rgba(10, 80, 200, 0.15);
      width: 50px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 2px; }
  .dictionaries th.functions {
    width: 96px;
    text-align: right; }
  .dictionaries td.functions {
    width: 96px;
    text-align: right; }
    .dictionaries td.functions > div {
      display: flex;
      justify-content: flex-end; }
    .dictionaries td.functions button + button {
      margin-left: 8px; }
  .dictionaries button {
    margin-left: 3px; }
