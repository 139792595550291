.word-popup {
  width: 350px;
  height: 490px;
  text-align: left; }
  .word-popup > .title {
    display: flex;
    align-items: left;
    padding-left: 20px;
    padding-top: 20px;
    height: 40px;
    font-weight: bold;
    color: #192D5A;
    font-size: 18px;
    text-align: left; }
  .word-popup .inner {
    padding: 10px 20px;
    overflow-y: auto; }
  .word-popup > .buttons {
    margin: 0;
    padding: 16px;
    height: 68px;
    box-sizing: border-box; }
    .word-popup > .buttons button {
      margin: 10px;
      width: 120px;
      border: none;
      font-size: 13px;
      font-weight: bold;
      box-sizing: border-box; }
  .word-popup form {
    margin: 0 auto;
    color: #192D5A;
    font-size: 12px; }
    .word-popup form > div {
      text-align: left;
      display: flex;
      align-items: left;
      margin: 10px 0; }
      .word-popup form > div.desc {
        margin-top: -8px;
        margin-left: 80px;
        font-size: 10px; }
    .word-popup form label {
      flex-shrink: 0;
      margin-right: 8px;
      padding: 6px 0;
      text-align: left;
      width: 72px;
      font-size: 12px;
      line-height: 1em;
      color: #192D5A; }
    .word-popup form input {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 40px;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      font-size: 1.0em;
      box-sizing: border-box; }
    .word-popup form select {
      padding-left: 10px;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      font-size: 1.0em;
      box-sizing: border-box;
      height: 40px;
      width: 100%; }
    .word-popup form button[type=button] {
      cursor: pointer;
      height: 30px; }
