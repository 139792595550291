.dictionary-popup {
  width: 350px;
  height: 210px; }
  .dictionary-popup .title {
    height: 60px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: bold;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex; }
  .dictionary-popup form input {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px; }
