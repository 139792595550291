.popup .dictionaries-upload-csv-file {
  width: 500px;
  height: 330px; }
  .popup .dictionaries-upload-csv-file .attachment {
    margin: 5px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 384px;
    text-align: center; }
  .popup .dictionaries-upload-csv-file .select-file {
    margin-bottom: 8px;
    color: #444444; }
    .popup .dictionaries-upload-csv-file .select-file > div {
      margin-bottom: 8px;
      text-align: center; }
    .popup .dictionaries-upload-csv-file .select-file button {
      padding: 8px 8px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 12px; }
  .popup .dictionaries-upload-csv-file .explanation {
    margin: 20px auto;
    font-size: 12px; }
  .popup .dictionaries-upload-csv-file .drag-and-drop {
    margin: 0 auto 20px auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 96px;
    box-sizing: border-box;
    border: 2px dashed #A1A1B2;
    color: #A1A1B2; }
  .popup .dictionaries-upload-csv-file .upload-file {
    margin: 20px auto;
    font-size: 14px; }
  .popup .dictionaries-upload-csv-file .drag-and-drop.enter {
    border: 2px dashed black;
    color: black; }
  .popup .dictionaries-upload-csv-file .drag-and-drop > p {
    margin: 8px 0; }
  .popup .dictionaries-upload-csv-file .documents {
    margin: 0;
    padding: 0;
    color: black;
    font-size: 0.8em; }
    .popup .dictionaries-upload-csv-file .documents ul {
      flex-direction: column; }
  .popup .dictionaries-upload-csv-file input[type="file"] {
    display: none; }
  .popup .dictionaries-upload-csv-file .buttons {
    margin: 0;
    padding: 16px;
    height: 68px;
    box-sizing: border-box; }
    .popup .dictionaries-upload-csv-file .buttons button {
      margin: 0 8px;
      width: 120px;
      border: none;
      border-radius: 4px;
      font-size: 13px;
      font-weight: bold;
      box-sizing: border-box; }
  .popup .dictionaries-upload-csv-file .errors {
    overflow-y: auto;
    margin-top: 10px;
    width: 380px;
    height: 200px;
    text-align: left; }
