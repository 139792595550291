// AMV_Styleguide.sketchのColorPalettesに相当する内容

// Color palettes

// Bland color

$palette-logo-color : #224488;

// UI design(アルファありはAMI追加定義)
$palette-deep-blue : #07347B;
$palette-deep-blue-a10 : rgba($palette-deep-blue, 0.1);
$palette-alt-blue : #0A46A5;
$palette-light-blue : #0A50C8;
$palette-light-blue-a10 : rgba($palette-light-blue, 0.1);
$palette-light-blue-a15 : rgba($palette-light-blue, 0.15);
$palette-light-blue-a25 : rgba($palette-light-blue, 0.25);
$palette-light-blue-a50 : rgba($palette-light-blue, 0.5);
$palette-yellow : #FFC800;
$palette-gold : #FFA000;

$palette-slate : #192D5A;
$palette-slate-a10 : rgba($palette-slate, 0.1);
$palette-slate-a15 : rgba($palette-slate, 0.15);
$palette-slate-a20 : rgba($palette-slate, 0.2);
$palette-slate-a25 : rgba($palette-slate, 0.25);
$palette-slate-a50 : rgba($palette-slate, 0.50);
$palette-slate-a70 : rgba($palette-slate, 0.7);
$palette-slate-a80 : rgba($palette-slate, 0.8);
$palette-gray300 : #E2E3E8;
$palette-gray200 : #EAEBEF;
$palette-gray200-a50 : rgba($palette-gray200, 0.5);
$palette-gray100 : #F4F5F7;
$palette-gray100-a50 : rgba($palette-gray100, 0.5);

$palette-white : #FFFFFF;

// AMI追加定義
$palette-dark-blue : #2D3C64;
$palette-dark-blue-a50 : rgba($palette-dark-blue, 0.5);

$palette-table-header-background : rgba(1, 81, 204, 0.15); //#0151CC アルファ 0.15

$palette-deep-green : #005037;
$palette-alt-green : #006937;
$palette-light-green : #1E7D2D;
$palette-light-green-a10 : rgba($palette-light-green, 0.1);
$palette-light-green-a15 : rgba($palette-light-green, 0.15);
$palette-light-green-a25 : rgba($palette-light-green, 0.25);

$palette-black : #000000;
$palette-black-a10 : rgba($palette-black, 0.1);
$palette-black-a20 : rgba($palette-black, 0.2);

$palette-red : #DD3B3B;
$palette-red-a15 : rgba($palette-red, 0.15);
$palette-red-a25 : rgba($palette-red, 0.25);

