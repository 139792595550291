.meeting-time-editor-popup {
  width: 630px;
  height: 500px;
  text-align: center;
  border: 1px solid #C3C3C3;
  -webkit-overflow-scrolling: touch; }

div.meeting-time-editor-popup {
  padding: 0; }

.meeting-time-editor-popup > .title {
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 40px;
  background-color: #EEEEF4;
  color: #113355;
  font-size: 16px;
  text-align: left; }

.meeting-time-editor-popup .inner {
  padding: 8px 40px 8px 72px;
  height: 250px;
  overflow-y: auto; }

.meeting-time-editor-popup > .buttons {
  margin: 0;
  padding: 16px;
  height: 68px;
  background-color: #EEEEF4;
  box-sizing: border-box; }
  .meeting-time-editor-popup > .buttons button {
    margin: 0 8px;
    width: 120px;
    border: none;
    border-radius: 4px;
    font-size: 11px;
    font-weight: bold;
    box-sizing: border-box; }

.meeting-time-editor-popup form {
  margin: 0 auto;
  color: #444444;
  font-size: 12px; }
  .meeting-time-editor-popup form > div {
    text-align: left;
    display: flex;
    align-items: center;
    margin: 16px 0;
    flex-direction: row; }
    .meeting-time-editor-popup form > div.desc {
      margin-top: -8px;
      margin-left: 80px;
      font-size: 10px; }
  .meeting-time-editor-popup form label {
    flex-shrink: 0;
    margin-right: 8px;
    padding: 6px 0;
    text-align: right;
    width: 72px;
    line-height: 1em; }
  .meeting-time-editor-popup form input, .meeting-time-editor-popup form select, .meeting-time-editor-popup form option {
    padding-left: 10px;
    height: 40px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    font-size: 1.0em;
    box-sizing: border-box; }
  .meeting-time-editor-popup form div.select {
    position: relative; }
    .meeting-time-editor-popup form div.select + div {
      margin-left: 16px; }
    .meeting-time-editor-popup form div.select select {
      appearance: none;
      padding-right: 45px;
      min-width: 184px; }
    .meeting-time-editor-popup form div.select input {
      padding-right: 45px;
      width: 184px; }
    .meeting-time-editor-popup form div.select .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 24px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      pointer-events: none;
      cursor: pointer; }
  .meeting-time-editor-popup form input[type=radio] {
    margin-right: 5px;
    width: auto;
    height: auto; }

.meeting-time-editor-popup ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .meeting-time-editor-popup ul li {
    margin-left: 10px;
    padding: 3px;
    display: flex;
    align-items: center;
    border-radius: 4px; }

.meeting-time-editor-popup .meeting-timepicker {
  display: flex;
  align-items: center; }
  .meeting-time-editor-popup .meeting-timepicker div.select {
    position: relative; }
    .meeting-time-editor-popup .meeting-timepicker div.select + div {
      margin-left: 0; }
    .meeting-time-editor-popup .meeting-timepicker div.select select {
      min-width: 78px; }
    .meeting-time-editor-popup .meeting-timepicker div.select input {
      position: absolute;
      padding-right: 0;
      width: 38px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }

.rc-time-picker-panel ul {
  display: block; }

.icn.close {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 2px;
  overflow: hidden;
  cursor: pointer; }
  .icn.close:hover {
    background: #DBDBDB; }
  .icn.close:active {
    background: #C9C9C9; }
  .icn.close:hover::before, .icn.close:hover::after {
    background: #9E9E9E; }
  .icn.close:active::before, .icn.close:active::after {
    background: #8C8C8C; }
  .icn.close::before, .icn.close::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 50%;
    top: 50%;
    left: 25%;
    margin-top: -1px;
    background: #C2C2C2; }
  .icn.close::before {
    transform: rotate(45deg); }
  .icn.close::after {
    transform: rotate(-45deg); }
  .icn.close.big {
    transform: scale(3); }
  .icn.close.hairline::before, .icn.close.hairline::after {
    height: 1px; }
  .icn.close.thick::before, .icn.close.thick::after {
    height: 4px;
    margin-top: -2px; }
  .icn.close.black::before, .icn.close.black::after {
    height: 8px;
    margin-top: -4px; }
  .icn.close.heavy::before, .icn.close.heavy::after {
    height: 12px;
    margin-top: -6px; }
  .icn.close.pointy::Before, .icn.close.pointy::after {
    width: 200%;
    left: -50%; }
  .icn.close.rounded::before, .icn.close.rounded::after {
    border-radius: 5px; }
  .icn.close.blades::before, .icn.close.blades::after {
    border-radius: 5px 0; }
  .icn.close.warp::before, .icn.close.warp::after {
    border-radius: 120% 0; }
  .icn.close.fat::before, .icn.close.fat::after {
    border-radius: 100%; }

.ipad .meeting-time-editor-popup .inner::-webkit-scrollbar {
  width: 15px; }

.ipad .meeting-time-editor-popup .inner::-webkit-scrollbar-track {
  background: #dddddd; }

.ipad .meeting-time-editor-popup .inner::-webkit-scrollbar-thumb {
  background: #2cc2e4; }

.ipad .meeting-time-editor-popup form div.select .icon > div {
  margin: 0 auto; }
