.customer-statistics {
  background-color: #F4F5F7;
  font-size: 12px;
  height: 100%; }
  .customer-statistics p {
    font-size: 16px; }
  .customer-statistics .customer-statistics-header {
    background-color: #F4F5F7; }
    .customer-statistics .customer-statistics-header .customer-statistics-header-top {
      width: 100%;
      height: 50px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px #e2e2e4;
      display: flex;
      flex-direction: row; }
      .customer-statistics .customer-statistics-header .customer-statistics-header-top .title {
        font-size: 16px;
        color: #192D5A;
        padding: 16px 8px 18px 15px;
        box-sizing: border-box;
        text-align: left;
        font-weight: bold;
        margin-right: auto; }
      .customer-statistics .customer-statistics-header .customer-statistics-header-top div.search {
        display: flex;
        margin: 5px 15px 5px 0; }
        .customer-statistics .customer-statistics-header .customer-statistics-header-top div.search > input {
          margin: 0;
          padding: 0 8px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          flex-grow: 1;
          font-size: 14px;
          box-sizing: border-box;
          width: calc(279px - 31px);
          height: 40px; }
    .customer-statistics .customer-statistics-header .customer-statistics-header-bottom {
      width: 100%;
      height: calc(98px - 15px - 15px - 5px);
      padding: 15px 0 15px 0;
      margin-left: 15px; }
      .customer-statistics .customer-statistics-header .customer-statistics-header-bottom .date-selector-title {
        line-height: 1em;
        color: #192D5A;
        font-size: 12px;
        margin-bottom: 8px;
        font-weight: bold;
        text-align: left; }
      .customer-statistics .customer-statistics-header .customer-statistics-header-bottom .date-selector {
        display: flex; }
        .customer-statistics .customer-statistics-header .customer-statistics-header-bottom .date-selector .hyphen {
          margin: auto 0;
          padding: 0 4px; }
          .customer-statistics .customer-statistics-header .customer-statistics-header-bottom .date-selector .hyphen .select-label {
            padding: 13px 5px;
            font-size: 14px; }
  .customer-statistics .customer-statistics-detail {
    height: 80%; }
