.meeting-score-area {
  text-align: center;
  margin-left: 0; }
  .meeting-score-area .meeting-score-table table {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
    table-layout: fixed; }
  .meeting-score-area .meeting-score-table .score-row {
    border-bottom: 1px solid #E2E3E8; }
  .meeting-score-area .meeting-score-table td {
    height: 50px;
    color: #192D5A;
    text-align: left; }
    .meeting-score-area .meeting-score-table td:last-child {
      text-align: right; }
  .meeting-score-area .meeting-score-table tr {
    height: 80px; }
  .meeting-score-area .meeting-score-table .total-row {
    height: 40px; }
  .meeting-score-area .meeting-score-table .title {
    width: 120px;
    height: 50px;
    padding-left: 0px;
    font-size: 14px;
    font-weight: bold; }
  .meeting-score-area .meeting-score-table .desc {
    -webkit-flex: 1;
    flex: 1;
    width: 370px;
    line-height: 1.5;
    padding-top: 15px;
    vertical-align: top; }
  .meeting-score-area .meeting-score-table .score {
    width: 140px;
    font-size: 16px; }
  .meeting-score-area .meeting-score-table .total-score {
    width: 140px;
    font-size: 18px;
    vertical-align: top;
    padding-top: 10px; }
  .meeting-score-area form {
    margin: 0 auto;
    font-size: 13px; }
    .meeting-score-area form input {
      text-align: center;
      width: 100px;
      height: 40px;
      border: 1px solid #EEEEEE;
      margin-right: 7px;
      border-radius: 4px;
      font-size: 1.0em;
      box-sizing: border-box; }
  .meeting-score-area .buttons {
    margin-top: 5px;
    text-align: center; }
    .meeting-score-area .buttons button {
      width: 120px;
      height: 30px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      box-sizing: border-box; }
