.meeting-minute .agendas {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #EAEBEF;
  font-size: 12px; }
  .meeting-minute .agendas > .agenda-parts {
    box-sizing: border-box;
    width: 100%; }
    .meeting-minute .agendas > .agenda-parts > .agenda {
      box-sizing: border-box;
      width: 100%;
      background-color: #FFFFFF;
      margin-bottom: 5px;
      color: #192D5A;
      font-size: 12px; }
      .meeting-minute .agendas > .agenda-parts > .agenda .agenda-icon {
        height: 20px;
        width: 20px;
        background-size: contain;
        align-items: center;
        margin-right: 10px; }
      .meeting-minute .agendas > .agenda-parts > .agenda.current {
        background-color: rgba(30, 125, 45, 0.1); }
        .meeting-minute .agendas > .agenda-parts > .agenda.current textarea:disabled {
          background-color: rgba(30, 125, 45, 0.1); }
      .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px #E2E3E8; }
        .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-title-button {
          padding-top: 20px;
          display: flex;
          flex-direction: row;
          align-items: center; }
          .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-title-button > div {
            display: flex;
            flex-direction: column;
            box-sizing: border-box; }
            .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-title-button > div.agenda-title-area {
              display: flex;
              flex-direction: column;
              text-align: left;
              -webkit-flex: 1;
              flex: 1;
              padding-left: 16px; }
              .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-title-button > div.agenda-title-area > .agenda-title {
                width: 100%;
                font-size: 14px;
                margin-right: 8px;
                word-break: break-all; }
            .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-title-button > div.agenda-button {
              width: 80px;
              height: 24px;
              text-align: right;
              margin-right: 16px; }
              .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-title-button > div.agenda-button > button {
                height: 100%;
                font-size: 13px; }
        .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-presenter-time {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 13px;
          margin-top: 15px;
          min-height: 28px; }
          .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-presenter-time > div {
            flex: 1; }
            .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-presenter-time > div.agenda-presenter-area {
              height: 100%;
              display: flex;
              padding-left: 16px;
              flex-direction: row;
              align-items: center;
              border-right: solid 1px #E2E3E8; }
            .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-header > .agenda-presenter-time > div.agenda-time {
              height: 100%;
              display: flex;
              padding-left: 16px;
              flex-direction: row;
              align-items: center; }
      .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 15px; }
        .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body > div {
          margin-top: 15px;
          width: 100%;
          padding: 0 15px;
          box-sizing: border-box; }
          .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body > div label {
            padding-left: 0; }
        .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body > .agenda-row {
          display: flex;
          flex-direction: row; }
          .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body > .agenda-row > div {
            box-sizing: border-box; }
            .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body > .agenda-row > div.agenda-deadline {
              width: 150px;
              display: flex;
              flex-direction: column; }
              .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body > .agenda-row > div.agenda-deadline label {
                padding-left: 0; }
            .meeting-minute .agendas > .agenda-parts > .agenda > .agenda-body > .agenda-row > div.agenda-person_in_charge {
              width: 190px;
              padding-right: 15px; }
  .meeting-minute .agendas > .agenda-footer {
    box-sizing: border-box;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF; }
    .meeting-minute .agendas > .agenda-footer > .next-date-area {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 16px; }
      .meeting-minute .agendas > .agenda-footer > .next-date-area > .next-date {
        width: 150px; }
      .meeting-minute .agendas > .agenda-footer > .next-date-area > .next-date-time {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 280px; }
    .meeting-minute .agendas > .agenda-footer > .agenda-overall_comment {
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      margin-top: 15px; }
  .meeting-minute .agendas label {
    display: block;
    padding-bottom: 2px;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px; }
  .meeting-minute .agendas input, .meeting-minute .agendas select, .meeting-minute .agendas option {
    padding-left: 10px;
    height: 40px;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box; }
  .meeting-minute .agendas textarea.target {
    height: 40px;
    padding-left: 10px;
    line-height: 20px;
    resize: none;
    box-sizing: border-box;
    word-break: break-all;
    overflow: hidden;
    width: 100%; }
  .meeting-minute .agendas .meeting-timepicker {
    display: flex;
    align-items: center; }
    .meeting-minute .agendas .meeting-timepicker div.select {
      position: relative; }
    .meeting-minute .agendas .meeting-timepicker div.hidden {
      display: none; }
    .meeting-minute .agendas .meeting-timepicker div.select + div {
      margin-left: 0; }
    .meeting-minute .agendas .meeting-timepicker div.select select {
      min-width: 60px; }

.rc-time-picker-panel ul {
  display: block; }
