.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000; }
  .progress > span {
    width: 80px;
    height: 80px; }
