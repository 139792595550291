.comment-tab {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: inset 1px 0 rgba(25, 45, 90, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
  margin-left: 1px; }
  .comment-tab > .header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    cursor: pointer;
    font-size: 15px;
    color: #192D5A;
    background-color: #F4F5F7;
    height: 40px; }
    .comment-tab > .header > div {
      width: 50%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center; }
      .comment-tab > .header > div:first-child {
        border-right: 1px solid #EAEBEF; }
    .comment-tab > .header > .selected {
      background-color: #FFFFFF; }
    .comment-tab > .header > .selectable {
      border-bottom: 1px solid #EAEBEF; }
      .comment-tab > .header > .selectable:hover {
        background-color: rgba(25, 45, 90, 0.2); }
      .comment-tab > .header > .selectable:active {
        background-color: rgba(25, 45, 90, 0.25); }
  .comment-tab > .body {
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden; }
