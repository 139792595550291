.meeting-statistics-radar .inner-box {
  flex-wrap: wrap;
  flex-direction: row; }
  .meeting-statistics-radar .inner-box .radar, .meeting-statistics-radar .inner-box .radar-emp {
    width: 260px;
    height: 260px;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center; }
  .meeting-statistics-radar .inner-box .radar canvas {
    width: 220px !important;
    height: 220px !important; }
  .meeting-statistics-radar .inner-box .radar.position-0 {
    border-right: 1px solid #E2E3E8; }
  .meeting-statistics-radar .inner-box .radar.position-1 {
    border-right: 1px solid #E2E3E8; }
  .meeting-statistics-radar .inner-box .line-mid {
    border-top: 1px solid #E2E3E8; }
  .meeting-statistics-radar .inner-box .line-last {
    border-top: 1px solid #E2E3E8;
    margin-bottom: 20px; }
  .meeting-statistics-radar .inner-box .position-0.line-first {
    border-radius: 3px 0 0 0; }
  .meeting-statistics-radar .inner-box .position-1.line-first {
    border-right: 1px solid #E2E3E8; }
  .meeting-statistics-radar .inner-box .position-2.line-first {
    border-radius: 0 3px 0 0; }
  .meeting-statistics-radar .inner-box .position-0.line-last {
    border-radius: 0 0 0 3px; }
  .meeting-statistics-radar .inner-box .radar.position-0 {
    border-right: 1px solid #E2E3E8; }
  .meeting-statistics-radar .inner-box .radar-emp.position-1.line-first {
    border-right: none; }
