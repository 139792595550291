.user-popup {
  width: 540px;
  height: 600px; }
  .user-popup > .title {
    height: 60px;
    padding-left: 50px;
    background-color: #0A46A5;
    color: #FFFFFF;
    font-size: 24px; }
  .user-popup > .inner {
    padding: 0 50px 30px 50px;
    overflow-y: auto; }
    .user-popup > .inner .name {
      margin-top: 24px;
      display: flex;
      flex-direction: row; }
      .user-popup > .inner .name .sei {
        margin-right: 10px; }
      .user-popup > .inner .name > div {
        width: 50%;
        box-sizing: border-box; }
    .user-popup > .inner .desc {
      padding-top: 10px;
      font-size: 10px; }
  .user-popup > .buttons {
    padding: 0 50px 0 50px;
    height: 80px; }
    .user-popup > .buttons > div {
      width: 100%;
      display: flex;
      flex-direction: column; }
      .user-popup > .buttons > div .button-area {
        display: flex;
        justify-content: space-between; }
  .user-popup form {
    font-size: 14px; }
    .user-popup form label {
      margin-top: 24px;
      font-size: 13px; }
    .user-popup form input,
    .user-popup form select {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 40px;
      box-sizing: border-box; }
