@charset "UTF-8";
.comments {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #192D5A;
  overflow-y: hidden; }
  .comments > .header {
    min-height: 105px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), inset 0 -1px rgba(25, 45, 90, 0.1); }
    .comments > .header > .functions {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .comments > .header > .functions .search {
        flex-grow: 1;
        display: flex;
        padding: 0 15px; }
        .comments > .header > .functions .search input {
          flex-grow: 1;
          padding: 0 8px;
          font-size: 14px;
          height: 40px;
          width: 220px;
          box-sizing: border-box;
          border-radius: 3px 0 0 3px; }
        .comments > .header > .functions .search button {
          min-width: initial;
          height: 40px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px; }
          .comments > .header > .functions .search button > div {
            margin: auto; }
      .comments > .header > .functions .commentfilter {
        width: 190px; }
      .comments > .header > .functions .speaker-selection-filter {
        width: 270px;
        padding-bottom: 5px; }
      .comments > .header > .functions .auto-comment-update-button {
        font-size: 13px;
        width: 60px;
        height: 26px;
        margin: 0 15px 4px 15px; }
      .comments > .header > .functions .filter {
        display: flex;
        flex-direction: row;
        height: 24px;
        align-items: center;
        padding-left: 15px; }
        .comments > .header > .functions .filter div.check {
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          margin-right: 4px;
          box-sizing: border-box;
          background-color: #EAEBEF;
          border-radius: 3px;
          content: '';
          text-align: center;
          line-height: 20px; }
        .comments > .header > .functions .filter label:hover div.check::before {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
        .comments > .header > .functions .filter label:active div.check {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
        .comments > .header > .functions .filter input[type="checkbox"] {
          display: none; }
          .comments > .header > .functions .filter input[type="checkbox"]:checked + div.check {
            background-color: #FFC800; }
            .comments > .header > .functions .filter input[type="checkbox"]:checked + div.check::before {
              color: #192D5A;
              content: '✔'; }
        .comments > .header > .functions .filter input[type="text"] {
          box-sizing: border-box;
          border-top: none !important;
          border-right: none !important;
          border-left: none !important;
          border-bottom: dashed 1px #192D5A !important;
          border-radius: unset;
          background-color: transparent;
          padding: 2px;
          margin-right: 2px;
          width: 20px; }
      .comments > .header > .functions .select {
        margin-right: 15px; }
        .comments > .header > .functions .select select {
          width: 87px;
          height: 30px;
          padding: 2px 8px; }
        .comments > .header > .functions .select .button-select {
          height: 28px; }
  .comments > .body {
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    margin-top: 5px;
    overflow-y: auto; }
    .comments > .body > .background {
      width: 100%;
      height: 100%;
      font-size: 13px;
      color: rgba(25, 45, 90, 0.7);
      pointer-events: none;
      text-align: center;
      padding-top: 10px;
      box-sizing: border-box; }
    .comments > .body > .comment-area {
      width: 100%;
      box-sizing: border-box;
      font-size: 13px; }
      .comments > .body > .comment-area:last-child {
        margin-bottom: 53px; }
      .comments > .body > .comment-area > .comment-box {
        margin: 8px;
        text-align: left;
        display: flex;
        flex-direction: column;
        border-radius: 4px; }
        .comments > .body > .comment-area > .comment-box:hover, .comments > .body > .comment-area > .comment-box:active, .comments > .body > .comment-area > .comment-box.selected {
          background-color: #F4F5F7; }
        .comments > .body > .comment-area > .comment-box > .comment {
          padding: 7px;
          width: 100%;
          box-sizing: border-box;
          word-break: break-word;
          font-weight: bold; }
        .comments > .body > .comment-area > .comment-box > .is_dropped {
          color: #DD3B3B; }
        .comments > .body > .comment-area > .comment-box > .comment-editing {
          max-width: 100%;
          resize: none;
          height: 28px; }
        .comments > .body > .comment-area > .comment-box > .translated-comment {
          padding: 7px;
          margin-top: 10px;
          width: 100%;
          box-sizing: border-box;
          background-color: rgba(10, 80, 200, 0.1);
          color: #0A50C8; }
          .comments > .body > .comment-area > .comment-box > .translated-comment .translate-footer {
            width: 100%;
            height: 20px;
            display: flex; }
            .comments > .body > .comment-area > .comment-box > .translated-comment .translate-footer > div {
              cursor: pointer;
              height: 20px;
              width: 20px; }
            .comments > .body > .comment-area > .comment-box > .translated-comment .translate-footer > a {
              margin-left: auto;
              margin-top: auto;
              position: relative; }
              .comments > .body > .comment-area > .comment-box > .translated-comment .translate-footer > a > div {
                position: absolute;
                right: 0;
                bottom: 0; }
        .comments > .body > .comment-area > .comment-box > .functions {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 20px;
          height: 22px;
          padding-left: 7px; }
          .comments > .body > .comment-area > .comment-box > .functions > .utterance {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px; }
          .comments > .body > .comment-area > .comment-box > .functions > div {
            margin-right: 3px; }
          .comments > .body > .comment-area > .comment-box > .functions > .timestamp {
            -webkit-flex: 1;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            font-size: 12px;
            color: rgba(25, 45, 90, 0.8);
            padding-right: 7px; }
            .comments > .body > .comment-area > .comment-box > .functions > .timestamp div:first-child {
              margin-right: 4px; }
            .comments > .body > .comment-area > .comment-box > .functions > .timestamp .name_confident {
              color: #1E7D2D; }
            .comments > .body > .comment-area > .comment-box > .functions > .timestamp .name_unconfident {
              color: #0A50C8; }
            .comments > .body > .comment-area > .comment-box > .functions > .timestamp .name_suspicious {
              color: #FFC800; }
            .comments > .body > .comment-area > .comment-box > .functions > .timestamp .label-sr {
              background-color: #777777;
              color: #FFFFFF;
              padding: 1px 6px;
              border-radius: 4px; }
      .comments > .body > .comment-area hr {
        margin: 0 auto;
        width: calc(100% - 20px);
        border-top: dashed 1px #E2E3E8;
        border-right: none;
        border-bottom: none;
        border-left: none; }
  .comments > .footer {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    box-shadow: inset 0 1px rgba(25, 45, 90, 0.1);
    position: relative; }
    .comments > .footer > div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .comments > .footer > div span {
        margin: 2px; }
      .comments > .footer > div.comment-all:hover, .comments > .footer > div.comment-mark:hover {
        opacity: 0.3; }
      .comments > .footer > div.order {
        position: relative;
        margin: 0 auto;
        border-left: dashed 1px #E2E3E8;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none; }
        .comments > .footer > div.order:hover {
          background-color: rgba(25, 45, 90, 0.2); }
        .comments > .footer > div.order > .menus {
          border-radius: 4px;
          position: absolute;
          flex-direction: column;
          top: -50px;
          left: 0;
          width: 87px;
          height: 63px;
          z-index: 20;
          background-color: #FFFFFF;
          color: #2D3C64;
          font-size: 12px;
          text-align: center;
          box-shadow: 0 4px 12px rgba(25, 45, 90, 0.25), 0 0 4px rgba(25, 45, 90, 0.15); }
          .comments > .footer > div.order > .menus > div {
            height: 50%;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center; }
            .comments > .footer > div.order > .menus > div > div:first-child {
              width: 30px; }
            .comments > .footer > div.order > .menus > div:hover {
              background-color: #F4F5F7; }
          .comments > .footer > div.order > .menus div.order-desc {
            border-radius: 4px 4px 0 0;
            border-bottom: 1px solid #F4F5F7; }
          .comments > .footer > div.order > .menus div.order-asc {
            border-radius: 0 0 4px 4px; }
  .comments .indicator {
    margin: 10px 0;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center; }
    .comments .indicator div {
      flex-grow: 1;
      text-align: center; }
