@charset "UTF-8";
.user-picker {
  display: flex;
  position: relative; }
  .user-picker > button {
    padding: 4px 24px;
    margin: 2px 0;
    border-radius: 4px; }
    .user-picker > button.largeButton {
      padding: 8px 24px; }
    .user-picker > button.clear {
      margin-left: 8px; }
  .user-picker .select-box > div {
    height: 300px;
    width: 300px;
    position: absolute;
    box-sizing: border-box;
    background-color: #FFFFFF;
    box-shadow: inset 1px 0 rgba(25, 45, 90, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    z-index: 2;
    left: 0;
    top: 32px; }
    .user-picker .select-box > div .options {
      display: flex;
      flex-direction: column;
      padding: 8px 20px;
      width: 100%;
      height: 190px;
      box-sizing: border-box;
      overflow-y: auto; }
      .user-picker .select-box > div .options > label {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        font-weight: normal;
        white-space: nowrap; }
        .user-picker .select-box > div .options > label div.check {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          margin-right: 8px;
          box-sizing: border-box;
          background-color: #EAEBEF;
          border-radius: 3px;
          content: ''; }
        .user-picker .select-box > div .options > label:hover div.check::before {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
        .user-picker .select-box > div .options > label:active div.check {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
      .user-picker .select-box > div .options input[type="checkbox"] {
        display: none; }
        .user-picker .select-box > div .options input[type="checkbox"]:checked + div.check {
          background-color: #FFC800; }
          .user-picker .select-box > div .options input[type="checkbox"]:checked + div.check::before {
            color: #192D5A;
            content: '✔'; }
    .user-picker .select-box > div .search {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      box-sizing: border-box;
      padding: 8px;
      border-bottom: solid 1px #E2E3E8;
      flex-grow: 1; }
      .user-picker .select-box > div .search input {
        padding: 0 8px;
        font-size: 14px;
        height: 40px;
        width: 230px;
        box-sizing: border-box;
        border-radius: 3px 0 0 3px; }
      .user-picker .select-box > div .search button {
        min-width: initial;
        height: 40px;
        width: 31px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px; }
        .user-picker .select-box > div .search button > div {
          margin: auto; }
    .user-picker .select-box > div .operations {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      box-shadow: inset 0 1px #E2E3E8;
      height: 50px;
      box-sizing: border-box; }
      .user-picker .select-box > div .operations button {
        width: 100px;
        height: 30px; }
  .user-picker label {
    flex-shrink: 0;
    padding: 12px 0;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    line-height: 1em; }

.ipad .user-picker .search button > div, .ipad .user-picker .operations button > div {
  margin: 0 auto; }

.ipad .user-picker .search input {
  line-height: 1.6; }
