.popup .translated-comment-popup {
  width: 630px;
  height: 500px;
  text-align: center;
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #192D5A; }
  .popup .translated-comment-popup .inner {
    padding: 0 50px; }
  .popup .translated-comment-popup > .title {
    display: flex;
    align-items: flex-start;
    padding: 18px 50px;
    font-size: 18px;
    height: 24px;
    text-align: left;
    background-color: #0A46A5;
    color: #FFFFFF; }
  .popup .translated-comment-popup > .buttons {
    margin: 0;
    padding: 20px 50px;
    height: 80px;
    box-sizing: border-box; }
    .popup .translated-comment-popup > .buttons button {
      margin: 0 8px;
      width: 100px;
      border: none;
      border-radius: 3px;
      font-size: 13px;
      font-weight: bold;
      box-sizing: border-box; }
  .popup .translated-comment-popup form {
    margin: 0 auto;
    font-size: 12px; }
    .popup .translated-comment-popup form > div {
      text-align: left;
      display: flex;
      align-items: flex-start;
      margin: 16px 0; }
    .popup .translated-comment-popup form label {
      flex-shrink: 0;
      margin-right: 8px;
      padding: 6px 0;
      text-align: left;
      width: 100px;
      font-weight: bold;
      line-height: 1em; }
    .popup .translated-comment-popup form textarea {
      width: 100%;
      border: 1px solid #EEEEEE;
      border-radius: 3px;
      font-size: 1.0em;
      box-sizing: border-box;
      height: 64px;
      padding: 6px 10px;
      max-width: 100%;
      resize: none; }
    .popup .translated-comment-popup form input {
      padding: 6px 10px; }
  .popup .translated-comment-popup .errors {
    margin: 5px 0;
    text-align: center;
    font-size: 12px; }
