.meeting-history {
  background-color: white;
  font-size: 20px;
  padding-top: 25px;
  color: #192D5A; }
  .meeting-history .meeting-history-body {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column; }
    .meeting-history .meeting-history-body .month-navigation {
      margin: 0 0 10px 0;
      display: flex;
      flex-direction: row;
      -webkit-flex-direction: row;
      align-items: center;
      -webkit-align-items: center;
      justify-content: center;
      -webkit-justify-content: center; }
      .meeting-history .meeting-history-body .month-navigation button {
        border: 0; }
      .meeting-history .meeting-history-body .month-navigation > div {
        width: 200px; }
    .meeting-history .meeting-history-body .card-area {
      flex-grow: 1;
      -webkit-flex-grow: 1;
      margin: 0 40px;
      display: flex;
      align-items: flex-start; }
      .meeting-history .meeting-history-body .card-area .card {
        width: 180px;
        height: 90px;
        margin: 10px 20px;
        display: flex;
        flex-direction: column;
        -webkit-flex-direction: column; }
        .meeting-history .meeting-history-body .card-area .card .card-title {
          text-align: left; }
        .meeting-history .meeting-history-body .card-area .card .card-body {
          flex-grow: 1;
          -webkit-flex-grow: 1;
          display: flex;
          flex-direction: row;
          -webkit-flex-direction: row;
          align-items: center; }
          .meeting-history .meeting-history-body .card-area .card .card-body div:nth-child(1) {
            padding: 10px; }
          .meeting-history .meeting-history-body .card-area .card .card-body div:nth-child(2) {
            padding: 10px;
            flex-grow: 1;
            -webkit-flex-grow: 1; }
          .meeting-history .meeting-history-body .card-area .card .card-body div:nth-child(3) {
            padding: 10px;
            align-self: flex-end; }
    .meeting-history .meeting-history-body .history-area {
      margin: 0 20px;
      overflow-y: auto;
      height: 400px;
      font-size: 14px; }
      .meeting-history .meeting-history-body .history-area .no-data {
        margin-top: 30px;
        font-size: 16px; }
      .meeting-history .meeting-history-body .history-area table img {
        border: 0;
        margin: .5em; }
      .meeting-history .meeting-history-body .history-area table {
        margin: 1em 0 1em 1px;
        padding: 0;
        border: 1px solid #878B92;
        border-spacing: 0;
        border-collapse: collapse; }
      .meeting-history .meeting-history-body .history-area caption {
        color: #9ba9b4;
        letter-spacing: .1em;
        margin: 1em 0 0 0;
        padding: 0;
        caption-side: top;
        text-align: center; }
      .meeting-history .meeting-history-body .history-area th.start_at {
        min-width: 120px; }
      .meeting-history .meeting-history-body .history-area th.end_at {
        min-width: 120px; }
      .meeting-history .meeting-history-body .history-area th.usage_string {
        min-width: 80px; }
      .meeting-history .meeting-history-body .history-area th.restart {
        min-width: 80px; }
      .meeting-history .meeting-history-body .history-area tr.odd td {
        background: #f7fbff; }
      .meeting-history .meeting-history-body .history-area tr.odd .column1 {
        background: #f4f9fe; }
      .meeting-history .meeting-history-body .history-area .column1 {
        /*background: #f9fcfe;*/
        min-width: 150px; }
      .meeting-history .meeting-history-body .history-area td {
        color: #2e3c49;
        border-bottom: 1px solid #878B92;
        border-left: 1px solid #878B92;
        padding: .3em 1em;
        text-align: center;
        /*min-width: 100px;*/ }
      .meeting-history .meeting-history-body .history-area th {
        font-weight: normal;
        color: #678197;
        text-align: left;
        border-bottom: 1px solid #878B92;
        border-left: 1px solid #878B92;
        padding: .3em 1em; }
      .meeting-history .meeting-history-body .history-area thead th {
        background: #f4f9fe;
        text-align: center;
        font: bold 1em/2em "Century Gothic", "Trebuchet MS", Arial, Helvetica, sans-serif;
        color: #66a3d3; }
      .meeting-history .meeting-history-body .history-area tfoot th {
        text-align: center;
        background: #f4f9fe; }
      .meeting-history .meeting-history-body .history-area tfoot th strong {
        font: bold 1em "Century Gothic", "Trebuchet MS", Arial, Helvetica, sans-serif;
        margin: .5em .5em .5em 0;
        color: #66a3d3; }
      .meeting-history .meeting-history-body .history-area tfoot th em {
        color: #f03b58;
        font-weight: bold;
        font-style: normal; }
