.informations {
  padding: 8px;
  border-top: 1.5px solid #EEEEEE;
  background-color: #F4F4FA; }

.informations > .title,
.informations .information {
  margin-left: auto;
  margin-right: auto;
  width: 720px; }

.informations > .title {
  color: #113355;
  font-size: 16px; }

.informations .information {
  margin: 0 auto;
  display: flex;
  color: #666666;
  font-size: 11px;
  line-height: 19px; }

.informations .information .date {
  text-align: right; }

.informations .information .title {
  margin-left: 10px;
  text-align: left;
  width: 20%;
  font-weight: bold; }

.informations .information .desc {
  margin-left: 10px;
  text-align: left;
  width: 50%;
  flex-grow: 1; }
