.pictures {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  color: #192D5A;
  position: relative; }
  .pictures > .header {
    position: absolute;
    top: -1px;
    height: 1px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), inset 0 -1px rgba(25, 45, 90, 0.1); }
  .pictures > .body {
    -webkit-flex: 1;
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    margin-top: 5px;
    overflow-y: auto; }
    .pictures > .body > .background {
      width: 100%;
      height: 100%;
      font-size: 13px;
      color: rgba(25, 45, 90, 0.7);
      pointer-events: none;
      text-align: center;
      padding-top: 10px;
      box-sizing: border-box; }
    .pictures > .body > .picture-area {
      width: 100%;
      box-sizing: border-box;
      font-size: 13px; }
      .pictures > .body > .picture-area > .picture-box {
        margin: 8px;
        text-align: left;
        display: flex;
        flex-direction: column;
        border-radius: 4px; }
        .pictures > .body > .picture-area > .picture-box:hover, .pictures > .body > .picture-area > .picture-box:active, .pictures > .body > .picture-area > .picture-box.selected {
          background-color: #F4F5F7; }
        .pictures > .body > .picture-area > .picture-box > .picture {
          min-height: 0;
          padding: 7px;
          width: 100%;
          height: 100%;
          box-sizing: border-box; }
          .pictures > .body > .picture-area > .picture-box > .picture img {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 300px; }
        .pictures > .body > .picture-area > .picture-box > .filename {
          padding: 0 7px; }
        .pictures > .body > .picture-area > .picture-box > .functions {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 20px; }
          .pictures > .body > .picture-area > .picture-box > .functions > .mark,
          .pictures > .body > .picture-area > .picture-box > .functions > .zoom {
            width: 25px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .pictures > .body > .picture-area > .picture-box > .functions > .mark > div,
            .pictures > .body > .picture-area > .picture-box > .functions > .zoom > div {
              cursor: pointer;
              background-size: 80% auto;
              background-position: center;
              height: 20px;
              width: 20px; }
          .pictures > .body > .picture-area > .picture-box > .functions > .buttons {
            width: 25px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .pictures > .body > .picture-area > .picture-box > .functions > .buttons > div {
              cursor: pointer;
              height: 20px;
              width: 20px; }
          .pictures > .body > .picture-area > .picture-box > .functions > .timestamp {
            -webkit-flex: 1;
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            font-size: 12px;
            color: rgba(25, 45, 90, 0.8);
            padding-right: 7px; }
            .pictures > .body > .picture-area > .picture-box > .functions > .timestamp div:first-child {
              margin-right: 4px; }
      .pictures > .body > .picture-area hr {
        margin: 0 auto;
        width: calc(100% - 20px);
        border-top: dashed 1px #E2E3E8;
        border-right: none;
        border-bottom: none;
        border-left: none; }
  .pictures > .footer {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    box-shadow: inset 0 1px rgba(25, 45, 90, 0.1);
    position: relative; }
    .pictures > .footer > div {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
      .pictures > .footer > div span {
        margin: 2px; }
      .pictures > .footer > div.comment-all:hover, .pictures > .footer > div.comment-mark:hover {
        opacity: 0.3; }
