.pagination-bar {
  height: 24px;
  margin: 0;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica, sans-serif; }
  .pagination-bar .button-paginate {
    color: #2D3C64 !important;
    background-color: transparent;
    cursor: pointer; }
    .pagination-bar .button-paginate a {
      color: #2D3C64 !important;
      border-style: none;
      cursor: pointer; }
    .pagination-bar .button-paginate:hover {
      background-color: #d1d5de; }
    .pagination-bar .button-paginate:disabled {
      cursor: default; }
    .pagination-bar .button-paginate:focus {
      outline: 0; }
  .pagination-bar > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    box-sizing: border-box; }
    .pagination-bar > div + div {
      margin-left: 2px; }
    .pagination-bar > div.current, .pagination-bar > div.prev, .pagination-bar > div.next {
      font-size: 16px;
      font-weight: bold; }
    .pagination-bar > div.current {
      width: 24px;
      border-radius: 3px;
      color: #FFFFFF;
      background-color: #192D5A; }
    .pagination-bar > div.prev, .pagination-bar > div.next {
      color: rgba(25, 45, 90, 0.3);
      width: 24px; }
    .pagination-bar > div > a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 24px;
      border-radius: 3px;
      font-size: 16px;
      cursor: pointer; }
    .pagination-bar > div.prev > a, .pagination-bar > div.next > a {
      width: 24px; }
