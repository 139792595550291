.custom-date-picker,
.custom-date-picker-for-agenda {
  position: relative;
  float: left; }
  .custom-date-picker .custom-date-picker-button,
  .custom-date-picker .custom-date-picker-short-button,
  .custom-date-picker .custom-date-picker-button-for-agenda,
  .custom-date-picker-for-agenda .custom-date-picker-button,
  .custom-date-picker-for-agenda .custom-date-picker-short-button,
  .custom-date-picker-for-agenda .custom-date-picker-button-for-agenda {
    padding-left: 10px;
    height: 40px;
    font-size: 14px;
    box-sizing: border-box;
    text-align: left;
    color: #2D3C64;
    background-color: #EAEBEF;
    border: 1px solid #EAEBEF;
    border-radius: 4px;
    cursor: pointer; }
    .custom-date-picker .custom-date-picker-button:hover,
    .custom-date-picker .custom-date-picker-short-button:hover,
    .custom-date-picker .custom-date-picker-button-for-agenda:hover,
    .custom-date-picker-for-agenda .custom-date-picker-button:hover,
    .custom-date-picker-for-agenda .custom-date-picker-short-button:hover,
    .custom-date-picker-for-agenda .custom-date-picker-button-for-agenda:hover {
      color: rgba(45, 60, 100, 0.5);
      border: 1px solid #a6b4cf; }
    .custom-date-picker .custom-date-picker-button:disabled,
    .custom-date-picker .custom-date-picker-short-button:disabled,
    .custom-date-picker .custom-date-picker-button-for-agenda:disabled,
    .custom-date-picker-for-agenda .custom-date-picker-button:disabled,
    .custom-date-picker-for-agenda .custom-date-picker-short-button:disabled,
    .custom-date-picker-for-agenda .custom-date-picker-button-for-agenda:disabled {
      color: rgba(45, 60, 100, 0.5);
      background-color: rgba(234, 235, 239, 0.5);
      border: 1px solid rgba(234, 235, 239, 0.5);
      cursor: default; }
  .custom-date-picker .custom-date-picker-button,
  .custom-date-picker-for-agenda .custom-date-picker-button {
    padding-right: 45px;
    width: 184px; }
  .custom-date-picker .custom-date-picker-short-button,
  .custom-date-picker-for-agenda .custom-date-picker-short-button {
    padding-right: 10px;
    width: 124px; }
  .custom-date-picker .custom-date-picker-button-for-agenda,
  .custom-date-picker-for-agenda .custom-date-picker-button-for-agenda {
    padding-right: 10px;
    width: 150px; }
  .custom-date-picker .button-select,
  .custom-date-picker-for-agenda .button-select {
    position: absolute;
    right: 8px;
    top: 11px;
    height: 22px;
    width: 22px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    pointer-events: none; }
  .custom-date-picker .react-datepicker__navigation--next,
  .custom-date-picker-for-agenda .react-datepicker__navigation--next {
    border-left-color: #2D3C64; }
  .custom-date-picker .react-datepicker__navigation--previous,
  .custom-date-picker-for-agenda .react-datepicker__navigation--previous {
    border-right-color: #2D3C64; }

.custom-date-picker .react-datepicker__close-icon {
  right: 36px; }
  .custom-date-picker .react-datepicker__close-icon::after {
    height: 18px !important;
    width: 18px !important;
    right: 40px !important;
    box-sizing: border-box;
    background-color: #192D5A; }
  .custom-date-picker .react-datepicker__close-icon:hover::after {
    background-color: rgba(25, 45, 90, 0.7); }
  .custom-date-picker .react-datepicker__close-icon:active::after {
    background-color: rgba(25, 45, 90, 0.5); }

.custom-date-picker-for-agenda .react-datepicker__close-icon {
  right: 20px; }
  .custom-date-picker-for-agenda .react-datepicker__close-icon::after {
    height: 18px !important;
    width: 18px !important;
    right: 40px !important;
    box-sizing: border-box;
    background-color: #192D5A; }
  .custom-date-picker-for-agenda .react-datepicker__close-icon:hover::after {
    background-color: rgba(25, 45, 90, 0.7); }
  .custom-date-picker-for-agenda .react-datepicker__close-icon:active::after {
    background-color: rgba(25, 45, 90, 0.5); }
