.member-tab {
  box-sizing: border-box;
  width: 100%; }
  .member-tab > button {
    padding: 0 10px;
    margin-left: 0;
    height: 30px;
    font-size: 13px; }
  .member-tab div.member-tabs {
    position: fixed;
    z-index: 100; }
    .member-tab div.member-tabs .member-content {
      box-shadow: inset 1px 0 rgba(25, 45, 90, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      width: 300px;
      position: absolute;
      top: 0;
      left: 0; }
      .member-tab div.member-tabs .member-content .user-picker .select-box > div, .member-tab div.member-tabs .member-content .group-picker .select-box > div {
        top: 0; }
      .member-tab div.member-tabs .member-content > .header {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        display: flex;
        align-items: stretch;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        color: #192D5A;
        background-color: #E2E3E8;
        height: 35px; }
        .member-tab div.member-tabs .member-content > .header > div {
          box-sizing: border-box;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border-top: 3px solid transparent; }
        .member-tab div.member-tabs .member-content > .header > .selected {
          background-color: #FFFFFF; }
        .member-tab div.member-tabs .member-content > .header > .selectable {
          border-bottom: 1px solid #EAEBEF; }
          .member-tab div.member-tabs .member-content > .header > .selectable:hover {
            background-color: rgba(25, 45, 90, 0.2); }
          .member-tab div.member-tabs .member-content > .header > .selectable:active {
            background-color: rgba(25, 45, 90, 0.25); }
        .member-tab div.member-tabs .member-content > .header.disabled {
          pointer-events: none; }
          .member-tab div.member-tabs .member-content > .header.disabled > .selected {
            border-top: 3px solid #E2E2E8;
            background-color: #EEEEF4; }
          .member-tab div.member-tabs .member-content > .header.disabled > .selectable {
            background-color: #E3E4EB; }
  .member-tab button.clear {
    margin: 0;
    height: 26px;
    width: 140px;
    border: none;
    border-radius: 4px;
    font-size: 11px;
    font-weight: bold;
    box-sizing: border-box; }
