.icon-volume {
  background: url(./images/volume.png) no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  cursor: pointer; }

.icon-volume-disabled {
  background: url(./images/volume-disabled.png) no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px 18px; }

.icon-playing {
  background: url(./images/playing.png) no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  cursor: pointer; }

.icon-playing-disabled {
  background: url(./images/playing-disabled.png) no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px 18px; }

.icon-calendar {
  background: url(./images/calendar.png) no-repeat;
  width: 11px;
  height: 11px;
  background-size: 11px 11px; }

.icon-clock {
  background: url(./images/clock.png) no-repeat;
  width: 11px;
  height: 11px;
  background-size: 11px 11px; }

.icon-house {
  background: url(./images/house.png) no-repeat;
  width: 11px;
  height: 11px;
  background-size: 11px 11px; }

.icon-user {
  background: url(./images/user.png) no-repeat;
  width: 11px;
  height: 11px;
  background-size: 11px 11px; }

.icon-document {
  background: url(./images/document.png) no-repeat;
  width: 11px;
  height: 11px;
  background-size: 11px 11px; }

.icon-dictionary {
  background: url(./images/dictionary.png) no-repeat;
  width: 11px;
  height: 11px;
  background-size: 11px 11px; }

.icon-flashcard {
  background: url(./images/flashcard.png) no-repeat;
  width: 11px;
  height: 11px;
  background-size: 11px 11px; }

.icon-download {
  background: url(./images/download.png) no-repeat;
  width: 12px;
  height: 12px;
  background-size: 12px 12px; }

.icon-search {
  background: url(./images/search.svg) no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px 18px; }

.icon-inverted-triangle {
  background: url(./images/inverted-triangle.svg) no-repeat;
  width: 12px;
  height: 12px;
  background-size: 12px 12px; }

.icon-inverted-triangle-disabled {
  background: url(./images/inverted-triangle-disabled.svg) no-repeat;
  width: 12px;
  height: 12px;
  background-size: 12px 12px; }

.icon-pencil {
  background-image: url(./images/pencil.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-fill-drip-solid {
  background-image: url(./images/fill-drip-solid.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  width: 20px;
  height: 20px; }

.icon-fill-drip-solid-disabled {
  background-image: url(./images/fill-drip-solid-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  width: 20px;
  height: 20px; }

.icon-content-copy {
  background-image: url(./images/content-copy.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 18px; }

.icon-send-mail {
  background-image: url(./images/send-mail.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 31px;
  height: 31px;
  cursor: pointer; }

.icon-top-usage {
  background-image: url(./images/top-usage.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-top-user {
  background-image: url(./images/top-user.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-trash {
  background-image: url(./images/trash.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px; }

.icon-super-scribe {
  background-image: url(./images/super-scribe.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px; }

.icon-chart-bar {
  background-image: url(./images/chart-bar.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-chart-pie {
  background: url(./images/chart-pie.svg) no-repeat center;
  fill: #44bbdd;
  width: 18px;
  height: 18px;
  padding: 4px;
  border-radius: 2px; }

.icon-picture {
  background: url(./images/picture.png) no-repeat;
  width: 20px;
  height: 20px;
  background-size: 20px 20px; }

.icon-left-arrow {
  background: url(./images/left-arrow.png) no-repeat;
  width: 12px;
  height: 12px;
  background-size: 12px 12px; }

.img-logo {
  width: 129px;
  height: 24px;
  background: url(./images/logo.png) no-repeat center;
  background-size: contain; }

.img-login-background {
  width: 730px;
  height: 593px;
  background: url(./images/login-background.svg) no-repeat center;
  background-size: contain; }

.img-top-logo {
  width: 248px;
  height: 46px;
  background: url(./images/top-logo.svg) no-repeat center;
  background-size: contain; }

.img-translated-by-google {
  width: 132px;
  height: 0.125in;
  background-image: url(./images/translated-by-google-greyscale-short.svg);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: contain; }

.img-translated-by-google-ie {
  width: 122px;
  height: 0.125in;
  background-image: url(./images/translated-by-google-greyscale-short-ie.svg);
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-size: contain; }

.icon-play {
  background: url(./images/play.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer; }

.icon-pause {
  background: url(./images/pause.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer; }

.icon-fast-forward {
  background: url(./images/fast-forward.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer; }

.icon-fast-rewind {
  background: url(./images/fast-rewind.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer; }

.icon-arrow-alt-circle-left {
  background: url(./images/arrow-alt-circle-left.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer; }

.icon-arrow-alt-circle-right {
  background: url(./images/arrow-alt-circle-right.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer; }

.icon-nav-analysis {
  background: url(./images/nav-analysis.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-nav-help {
  background: url(./images/nav-help.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-nav-home {
  background: url(./images/nav-home.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-nav-member {
  background: url(./images/nav-member.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-nav-memo {
  background: url(./images/nav-memo.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-nav-settings {
  background: url(./images/nav-settings.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-nav-words {
  background: url(./images/nav-words.svg) no-repeat;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-star-enabled {
  background-image: url(./images/star-enabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 26px;
  height: 26px; }

.icon-star-disabled {
  background-image: url(./images/star-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 26px;
  height: 26px; }

.icon-star-not-selected {
  background-image: url(./images/star-not-selected.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 26px;
  height: 26px; }

.icon-option {
  background-image: url(./images/option.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-plus {
  background-image: url(./images/plus.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-plus-disabled {
  background-image: url(./images/plus-disabled.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-comment-all {
  background-image: url(./images/comment-all.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 31px;
  height: 31px; }

.icon-comment-all-not-selected {
  background-image: url(./images/comment-all-not-selected.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 31px;
  height: 31px; }

.icon-jump {
  background-image: url(./images/jump.svg);
  background-repeat: no-repeat;
  background-position: center; }

.icon-order {
  background-image: url(./images/order.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px; }

.icon-times-solid {
  background: url(./images/times-solid.svg) no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px 18px;
  cursor: pointer; }

.icon-download {
  background-image: url(./images/download.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 30px;
  height: 30px; }

.icon-open {
  background-image: url(./images/open.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: solid 2px #EAEBEF;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-closed {
  background-image: url(./images/closed.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: solid 2px #EAEBEF;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  background-size: 24px 24px; }

.icon-attachment-small {
  background-image: url(./images/attachment.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-book-small {
  background-image: url(./images/book.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-book-navy-small {
  background-image: url(./images/book-navy.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-calendar-navy-small {
  background-image: url(./images/calendar-navy.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-check-small {
  background-image: url(./images/check.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-clock-small {
  background-image: url(./images/clock.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-down-large {
  background-image: url(./images/down.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  background-size: 21px 21px; }

.icon-download-small {
  background-image: url(./images/download.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-pencil-medium {
  background-image: url(./images/pencil.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px;
  background-size: 16px 16px; }

.icon-pencil-disabled-medium {
  background-image: url(./images/pencil-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px;
  background-size: 16px 16px; }

.icon-pencil-blue-medium {
  background-image: url(./images/pencil-blue.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-star-enabled-medium {
  background-image: url(./images/star-enabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-star-disabled-medium {
  background-image: url(./images/star-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-star-not-selected-medium {
  background-image: url(./images/star-not-selected.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-star-not-selected-disabled-medium {
  background-image: url(./images/star-not-selected-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-start-small {
  background-image: url(./images/start.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-start-small-disabled {
  background-image: url(./images/start-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-stop-small {
  background-image: url(./images/stop.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-stop-small-disabled {
  background-image: url(./images/stop-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-jump-medium {
  background-image: url(./images/jump.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-stopwatch-small {
  background-image: url(./images/stopwatch.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-trash-large {
  background-image: url(./images/trash.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  background-size: 21px 21px; }

.icon-trash-medium {
  background-image: url(./images/trash.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-trash-disabled-medium {
  background-image: url(./images/trash-disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }

.icon-link-small {
  background-image: url(./images/link.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-mail-small {
  background-image: url(./images/mail.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-microphone-small {
  background-image: url(./images/microphone.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-microphone-navy-small {
  background-image: url(./images/microphone-navy.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-minutes-navy-small {
  background-image: url(./images/minutes-navy.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-place-small {
  background-image: url(./images/place.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-place-navy-small {
  background-image: url(./images/place-navy.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-user-small {
  background-image: url(./images/user.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-users-small {
  background-image: url(./images/users.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-users-navy-small {
  background-image: url(./images/users-navy.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 16px;
  height: 16px;
  background-size: 13px 13px; }

.icon-zoom-medium {
  background-image: url(./images/zoom.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  background-size: 17px 17px; }
