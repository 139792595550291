.password-reset {
  position: absolute;
  width: 100%;
  height: 400px;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center; }

.password-reset .logo .img-top-logo {
  margin: 0 auto; }

.password-reset .desc {
  width: 36%;
  margin: 0 auto;
  font-size: 0.8em; }

.password-reset .desc p {
  text-align: left; }

.password-reset form {
  margin: 20px auto;
  width: 90%;
  font-size: 0.8em; }

.password-reset form > div {
  display: flex;
  margin: 10px auto;
  width: 40%; }

.password-reset form input {
  width: 100%;
  font-size: 1.2em;
  padding: 5px 10px;
  border: 1.5px solid;
  border-radius: 4px;
  border-color: #eeeeee; }

.password-reset form input:hover {
  border-color: #a7b4cf; }

.password-reset form .message a {
  color: red; }

.password-reset form .buttons a {
  width: 100px;
  text-align: center;
  text-decoration: none;
  margin: 20px auto 0 auto;
  line-height: 40px;
  height: 40px;
  font-size: 0.8em;
  border-style: none;
  border-radius: 4px; }

.password-reset form input[type=submit] {
  width: 30%;
  margin: 20px auto 0 auto;
  height: 40px;
  font-size: 0.8em;
  border-style: none;
  border-radius: 4px; }
