.app {
  margin: 0 auto;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column; }

.app-header {
  background-color: #222;
  height: 50px;
  padding: 10px 10px 0 10px;
  color: white; }

.app h2 {
  margin: 10px 0;
  padding: 0; }

.app-intro {
  font-size: large; }

.app .hide {
  display: none; }

.app .menu {
  display: flex;
  height: 39px;
  border-bottom: 1px solid #E2E3E8; }
  .app .menu .logo {
    padding: 8px 0 0 20px; }
  .app .menu .user {
    width: 100%;
    margin: 8px 8px 8px 0;
    line-height: 28px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end; }
    .app .menu .user span {
      text-align: left;
      margin-left: 6px;
      margin-right: 10px; }
  .app .menu ul {
    width: 100%;
    color: #224488;
    margin-left: 30px;
    margin-top: 23px;
    font-size: 15px; }

.app .main-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  background-color: #ffffff;
  overflow: hidden; }
  .app .main-wrapper .side-menu {
    width: 70px;
    background-color: #0A46A5;
    position: sticky;
    top: 0px;
    height: 100%; }
  .app .main-wrapper .main-content {
    -webkit-flex: 1;
    flex: 1; }
    .app .main-wrapper .main-content.scrollable-main-content {
      overflow-y: auto; }

.side-menu > a:link {
  color: transparent; }

.side-menu > a:nth-last-child(2) .side-menu-item {
  margin-top: 70px; }

.side-menu > div:nth-last-child(2) .side-menu-item {
  margin-top: 70px; }

a > .side-menu-item {
  width: 67px;
  height: 70px;
  border-right: 3px solid #0A46A5; }

div > .side-menu-item {
  width: 67px;
  height: 70px;
  background-color: #07347B;
  border-right: 3px solid #FFC800; }

.side-menu-item div {
  position: relative;
  top: 14px;
  left: 23px; }

.side-menu-item span {
  position: relative;
  top: 13px;
  font-size: 11px;
  color: white; }

.top-usage-menu {
  position: relative; }

.top-option-menu {
  position: relative; }
  .top-option-menu .top-option-items {
    position: absolute;
    right: 0;
    top: 30px;
    width: 130px;
    border-radius: 4px;
    flex-direction: column;
    z-index: 20;
    background-color: #FFFFFF;
    color: #2D3C64;
    font-size: 12px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(25, 45, 90, 0.25), 0 0 4px rgba(25, 45, 90, 0.15);
    cursor: pointer; }
    .top-option-menu .top-option-items > div {
      height: 50%;
      width: 100%;
      box-sizing: border-box;
      padding-top: 4px;
      padding-right: 10px;
      padding-left: 10px; }
      .top-option-menu .top-option-items > div:hover {
        background-color: #F4F5F7; }
    .top-option-menu .top-option-items div.first-group {
      border-radius: 4px 4px 0 0;
      border-bottom: 1px solid #F4F5F7; }
    .top-option-menu .top-option-items div.second-group {
      border-radius: 0 0 4px 4px; }
