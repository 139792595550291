.dictionaries .words {
  font-size: 12px;
  background-color: white;
  height: 100%; }
  .dictionaries .words p {
    font-size: 16px; }
  .dictionaries .words .words-header {
    margin-bottom: 10px;
    box-shadow: 0 2px 4px #e2e2e4;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px; }
    .dictionaries .words .words-header .words-title {
      padding: 8px 16px;
      color: #192D5A;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 410px; }
    .dictionaries .words .words-header > .words-title {
      padding: 8px 8px 8px 16px;
      box-sizing: border-box;
      text-align: left;
      font-size: 16px;
      font-weight: bold; }
    .dictionaries .words .words-header button {
      margin-left: 11px;
      padding: 9px 14px;
      height: 30px;
      font-size: 13px; }
  .dictionaries .words .data {
    padding: 16px 20px;
    overflow-y: auto;
    height: calc(100% - 60px - 64px - 32px); }
    .dictionaries .words .data table {
      width: 100%;
      text-align: left;
      border-collapse: separate;
      border-spacing: inherit;
      font-size: 12px;
      table-layout: fixed;
      overflow: hidden; }
      .dictionaries .words .data table thead {
        background-color: rgba(1, 81, 204, 0.15); }
        .dictionaries .words .data table thead th {
          padding: 8px;
          text-align: center;
          color: #192D5A; }
      .dictionaries .words .data table tbody tr {
        padding: 3px;
        height: 60px;
        cursor: pointer; }
      .dictionaries .words .data table tbody td {
        box-sizing: border-box;
        border-bottom: 1px solid #E2E3E8;
        background-color: #FFFFFF; }
      .dictionaries .words .data table tbody tr:hover td {
        background-color: #EAEBEF; }
      .dictionaries .words .data table tbody td {
        padding: 8px;
        color: #192D5A; }
    .dictionaries .words .data th.word, .dictionaries .words .data td.word {
      text-align: left;
      padding-left: 15px; }
    .dictionaries .words .data th.yomi, .dictionaries .words .data td.yomi {
      text-align: left; }
    .dictionaries .words .data th.category, .dictionaries .words .data td.category {
      width: 200px;
      text-align: left; }
    .dictionaries .words .data th.functions {
      width: 96px;
      text-align: right; }
    .dictionaries .words .data td.functions {
      width: 96px;
      text-align: right; }
      .dictionaries .words .data td.functions > div {
        display: flex;
        justify-content: flex-end; }
      .dictionaries .words .data td.functions button + button {
        margin-left: 8px; }
  .dictionaries .words button {
    margin-left: 3px; }

.words-upload-csv-file > .drag-and-drop {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 200px;
  border: 1px dotted silver;
  color: silver; }
  .words-upload-csv-file > .drag-and-drop.enter {
    border: 1px dotted black;
    color: black; }

.words-upload-csv-file input[type="file"] {
  display: none; }

.words-results-upload .errors {
  overflow-y: auto;
  margin-top: 10px;
  width: 380px;
  height: 200px;
  text-align: left; }
