.my-group-users {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #FFFFFF;
  color: #192D5A;
  display: flex;
  flex-direction: column; }
  .my-group-users div.header {
    margin: 1px;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    box-shadow: 0 2px 4px rgba(25, 45, 90, 0.2);
    width: 100%;
    box-sizing: border-box; }
    .my-group-users div.header > .title {
      padding: 0 15px;
      font-size: 18px;
      max-width: calc(100% - 320px);
      box-sizing: border-box;
      word-break: break-all; }
    .my-group-users div.header > .operations {
      width: 320px; }
    .my-group-users div.header button {
      padding: 0;
      width: 111px;
      height: 30px;
      font-size: 13px; }
  .my-group-users div.no-data, .my-group-users div.data {
    margin: 3px 1px 0 1px;
    padding: 15px 15px 0 15px;
    font-size: 13px;
    background-color: #FFFFFF;
    -webkit-flex: 1;
    flex: 1; }
  .my-group-users div.data {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    overflow-y: hidden; }
    .my-group-users div.data div.data-header {
      width: 100%;
      box-sizing: border-box;
      background-color: rgba(10, 80, 200, 0.15);
      border-radius: 2px; }
      .my-group-users div.data div.data-header table {
        width: 100%;
        box-sizing: border-box;
        table-layout: fixed;
        border-collapse: collapse; }
        .my-group-users div.data div.data-header table thead tr {
          height: 30px; }
        .my-group-users div.data div.data-header table thead th {
          padding: 0 10px;
          font-size: 12px;
          font-weight: normal;
          height: 30px; }
    .my-group-users div.data div.data-detail {
      width: 100%;
      box-sizing: border-box;
      -webkit-flex: 1;
      flex: 1;
      overflow-y: auto; }
      .my-group-users div.data div.data-detail table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        box-sizing: border-box; }
        .my-group-users div.data div.data-detail table thead th {
          background-color: rgba(1, 81, 204, 0.15);
          padding: 10px 10px; }
          .my-group-users div.data div.data-detail table thead th:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px; }
          .my-group-users div.data div.data-detail table thead th:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px; }
        .my-group-users div.data div.data-detail table tbody tr {
          height: 60px;
          word-break: break-all;
          box-shadow: inset 0px -1px #E2E3E8; }
        .my-group-users div.data div.data-detail table tbody td {
          padding: 0 10px; }
        .my-group-users div.data div.data-detail table tbody tr:hover td {
          background-color: #EAEBEF; }
    .my-group-users div.data th.name, .my-group-users div.data td.name {
      width: 40%;
      text-align: left; }
    .my-group-users div.data th.disp, .my-group-users div.data td.disp {
      width: 25%;
      text-align: left; }
    .my-group-users div.data th.dept, .my-group-users div.data td.dept {
      width: 25%;
      text-align: left; }
    .my-group-users div.data th.functions, .my-group-users div.data td.functions {
      width: 10%;
      text-align: right; }
