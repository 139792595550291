.meeting-extraction {
  width: 100%;
  background-color: #F4F5F7;
  color: #192D5A; }
  .meeting-extraction div {
    display: flex;
    flex-direction: column; }
  .meeting-extraction .header {
    margin-bottom: 10px;
    box-shadow: 0 2px 4px #e2e2e4;
    font-size: 14px;
    background-color: #FFFFFF;
    flex-direction: row; }
    .meeting-extraction .header .title {
      font-size: 16px;
      color: #192D5A;
      text-align: left;
      padding: 16px 0 18px 15px;
      font-weight: bold;
      max-width: 1024px;
      box-sizing: border-box;
      margin-right: 26px;
      word-break: break-all; }
    .meeting-extraction .header .left-buttons {
      margin: 10px auto 0 0;
      height: 30px;
      box-sizing: border-box;
      flex-direction: row; }
    .meeting-extraction .header button {
      padding: 10px 16px;
      margin: 0 0 0 10px;
      height: 33px;
      font-size: 13px;
      box-sizing: border-box; }
  .meeting-extraction .meeting-statistics-area {
    width: 1100px; }
    .meeting-extraction .meeting-statistics-area .meeting-statistics-score {
      display: inline; }
  .meeting-extraction label.title {
    font-size: 16px;
    color: #192D5A;
    text-align: left;
    padding: 16px 0 5px 15px;
    font-weight: bold; }
  .meeting-extraction .no-data {
    padding: 100px;
    width: 500px; }
  .meeting-extraction .score-area {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 30px; }
    .meeting-extraction .score-area div.score-list-detail {
      width: 820px;
      box-sizing: border-box;
      text-align: left; }
  .meeting-extraction table {
    width: 780px;
    text-align: left;
    border-collapse: separate;
    font-size: 12px;
    table-layout: fixed;
    overflow: hidden;
    margin-left: 15px;
    border-spacing: 0 1px;
    border-radius: 3px; }
    .meeting-extraction table thead {
      background-color: rgba(1, 81, 204, 0.15); }
      .meeting-extraction table thead tr:first-child th:first-child {
        border-top-left-radius: 3px; }
      .meeting-extraction table thead tr:first-child th:last-child {
        border-top-right-radius: 3px; }
      .meeting-extraction table thead th {
        padding: 8px;
        text-align: left;
        color: #07347B; }
        .meeting-extraction table thead th.title, .meeting-extraction table thead th.room {
          text-align: left; }
    .meeting-extraction table tbody tr {
      height: 50px; }
      .meeting-extraction table tbody tr:last-child td:first-child {
        border-bottom-left-radius: 3px; }
      .meeting-extraction table tbody tr:last-child td:last-child {
        border-bottom-right-radius: 3px; }
    .meeting-extraction table tbody td {
      background-color: #FFFFFF;
      overflow-wrap: break-word;
      padding: 0 8px; }
      .meeting-extraction table tbody td:first-child {
        border-left: 2px solid transparent; }
      .meeting-extraction table tbody td:last-child {
        border-right: 2px solid transparent; }
  .meeting-extraction div.scroll {
    height: calc(100vh - 100px);
    overflow: auto; }
