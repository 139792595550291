.open-client {
  position: absolute;
  width: 100%;
  height: 400px;
  top: 0;
  bottom: 0;
  margin: auto;
  text-align: center; }
  .open-client .logo .img-top-logo {
    margin: 0 auto; }
  .open-client .desc {
    width: 50%;
    margin: 30px auto;
    font-size: 0.8em; }
    .open-client .desc p {
      text-align: left;
      line-height: 18px; }
  .open-client .buttons {
    margin: 20px auto;
    width: 90%;
    font-size: 0.8em; }
    .open-client .buttons .button-1 {
      padding: 5px 10px;
      height: 30px; }
