.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9980;
  color: #192D5A; }
  .alert > div {
    padding: 0;
    opacity: 1.0;
    background-color: #FFFFFF;
    text-align: left;
    display: flex;
    flex-direction: column;
    border-radius: 4px; }
  .alert .title {
    padding-top: 20px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: normal; }
  .alert .inner {
    padding: 20px 20px 0;
    -webkit-flex: 1;
    flex: 1;
    font-size: 12px; }
  .alert .buttons {
    box-shadow: inset 0 1px #E2E3E8;
    padding: 10px 20px 0 20px;
    height: 40px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    text-align: center; }
    .alert .buttons button {
      margin: 0;
      width: 100px;
      height: 30px;
      font-size: 13px;
      border-radius: 4px;
      cursor: pointer; }

.alert-popup {
  width: 400px;
  height: 180px;
  text-align: center; }
