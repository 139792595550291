@charset "UTF-8";
html {
  margin: 0 auto;
  padding: 0;
  height: 100vh;
  background-color: Gainsboro; }

body {
  margin: 0 auto;
  padding: 0;
  min-width: 1200px;
  height: 100vh;
  font-family: "ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","メイリオ","Meiryo","游ゴシック","Yu Gothic",sans-serif;
  background-color: white; }
  body.ipad {
    min-width: initial; }

h1, h2, h3, h4, h5 {
  margin: 0;
  padding: 0; }

ul {
  padding: 0;
  width: 100%;
  display: inline-flex;
  justify-content: space-around;
  list-style-type: none; }
  ul a {
    text-decoration: none;
    cursor: pointer; }

a:link, a:visited, a:hover, a:active {
  color: black; }

select {
  height: 30px;
  font-size: 1.0em;
  color: #2D3C64;
  outline: 0;
  background-color: #EAEBEF;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  appearance: none; }
  select:hover {
    border: 1px solid rgba(10, 80, 200, 0.5); }
  select:focus {
    border: 1px solid #0A50C8; }
  select:focus::-ms-value {
    color: #2D3C64; }
  select:disabled {
    background-color: rgba(234, 235, 239, 0.5);
    color: rgba(45, 60, 100, 0.5);
    border: 1px solid rgba(234, 235, 239, 0.5); }
  select::-ms-expand {
    display: none; }

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset; }

input:focus {
  outline: 0; }

input[type=text], input[type=search], input[type=email], input[type=password] {
  -webkit-appearance: none;
  border-radius: 3px;
  color: #2D3C64;
  background-color: #EAEBEF;
  border: 1px solid #EAEBEF !important; }

input[type=text]:hover, input[type=search]:hover, input[type=email]:hover, input[type=password]:hover {
  color: rgba(45, 60, 100, 0.5);
  border: 1px solid #a6b4cf !important; }

input[type=text]:focus, input[type=search]:focus, input[type=email]:focus, input[type=password]:focus {
  color: #2D3C64;
  border: 1px solid #0A50C8 !important; }

input[type=text]:disabled, input[type=search]:disabled, input[type=email]:disabled, input[type=password]:disabled {
  color: rgba(45, 60, 100, 0.5);
  background-color: rgba(234, 235, 239, 0.5);
  border: 1px solid rgba(234, 235, 239, 0.5) !important; }

input[type=range]::-ms-track {
  width: 300px;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent; }

input[type=range]::-ms-fill-lower {
  background: #777;
  border-radius: 10px; }

input[type=range]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px; }

input[type=range]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: gray; }

input[type=range]:focus::-ms-fill-lower {
  background: #888; }

input[type=range]:focus::-ms-fill-upper {
  background: #ccc; }

textarea {
  border-radius: 3px;
  color: #2D3C64;
  background-color: #EAEBEF;
  border: 1px solid #EAEBEF; }
  textarea:hover {
    color: rgba(45, 60, 100, 0.5);
    border: 1px solid rgba(10, 80, 200, 0.5); }
  textarea:focus {
    color: #2D3C64;
    outline: 0;
    border: 1px solid #0A50C8; }
  textarea:disabled {
    color: rgba(45, 60, 100, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    border: 1px solid rgba(234, 235, 239, 0.5); }

option {
  height: 30px;
  font-size: 1.0em; }

button {
  font-size: 1.0em; }
  button:focus {
    outline: 0; }

.error {
  color: red;
  font-size: 13px; }

.warning {
  color: rgba(25, 45, 90, 0.7);
  font-size: 13px; }

.button-1, .btnpt4, .btnpt5, .btnpt6, .btnpt7 {
  color: #2D3C64;
  background-color: #FFC800;
  border-style: none;
  border-radius: 3px;
  cursor: pointer; }
  .button-1 a, .btnpt4 a, .btnpt5 a, .btnpt6 a, .btnpt7 a {
    color: #2D3C64 !important;
    border-style: none;
    cursor: pointer; }
  .button-1:hover, .btnpt4:hover, .btnpt5:hover, .btnpt6:hover, .btnpt7:hover {
    background-color: #FFA000; }
  .button-1:active, .btnpt4:active, .btnpt5:active, .btnpt6:active, .btnpt7:active {
    background-color: #EB8C00; }
  .button-1:disabled, .btnpt4:disabled, .btnpt5:disabled, .btnpt6:disabled, .btnpt7:disabled {
    color: rgba(45, 60, 100, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-1:focus, .btnpt4:focus, .btnpt5:focus, .btnpt6:focus, .btnpt7:focus {
    outline: 0; }

.button-1-green {
  color: #FFFFFF;
  background-color: #1E7D2D;
  border-style: none;
  border-radius: 3px;
  cursor: pointer; }
  .button-1-green a {
    color: #FFFFFF !important;
    border-style: none;
    cursor: pointer; }
  .button-1-green:hover {
    background-color: #006937; }
  .button-1-green:active {
    background-color: #005037; }
  .button-1-green:disabled {
    color: rgba(45, 60, 100, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-1-green:focus {
    outline: 0; }

.button-1-gray {
  color: #192D5A;
  background-color: #EAEBEF;
  border-style: none;
  border-radius: 3px;
  cursor: pointer; }
  .button-1-gray a {
    color: #192D5A !important;
    border-style: none;
    cursor: pointer; }
  .button-1-gray:hover {
    background-color: #E2E3E8; }
  .button-1-gray:active {
    background-color: #E2E3E8; }
  .button-1-gray:disabled {
    color: rgba(45, 60, 100, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-1-gray:focus {
    outline: 0; }

.button-2 {
  color: #0A50C8;
  background-color: transparent;
  border: 1px solid #0A50C8;
  border-radius: 3px;
  cursor: pointer; }
  .button-2 a {
    color: #0A50C8 !important;
    border-style: none;
    cursor: pointer; }
  .button-2:hover {
    background-color: rgba(10, 80, 200, 0.15); }
  .button-2:active {
    background-color: rgba(10, 80, 200, 0.25); }
  .button-2:disabled {
    color: rgba(45, 60, 100, 0.5);
    border-color: rgba(234, 235, 239, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-2:focus {
    outline: 0; }

.button-2-green {
  color: #1E7D2D;
  background-color: transparent;
  border: 1px solid #1E7D2D;
  border-radius: 3px;
  cursor: pointer; }
  .button-2-green a {
    color: #1E7D2D !important;
    border-style: none;
    cursor: pointer; }
  .button-2-green:hover {
    background-color: rgba(30, 125, 45, 0.15); }
  .button-2-green:active {
    background-color: rgba(30, 125, 45, 0.25); }
  .button-2-green:disabled {
    color: rgba(45, 60, 100, 0.5);
    border-color: rgba(234, 235, 239, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-2-green:focus {
    outline: 0; }

.button-2-red {
  color: #DD3B3B;
  background-color: transparent;
  border: 1px solid #DD3B3B;
  border-radius: 3px;
  cursor: pointer; }
  .button-2-red a {
    color: #DD3B3B !important;
    border-style: none;
    cursor: pointer; }
  .button-2-red:hover {
    background-color: rgba(221, 59, 59, 0.15); }
  .button-2-red:active {
    background-color: rgba(221, 59, 59, 0.25); }
  .button-2-red:disabled {
    color: rgba(45, 60, 100, 0.5);
    border-color: rgba(234, 235, 239, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-2-red:focus {
    outline: 0; }

.button-2-a25 {
  color: rgba(10, 80, 200, 0.5);
  background-color: transparent;
  border: 1px solid rgba(10, 80, 200, 0.5);
  border-radius: 3px;
  cursor: pointer; }
  .button-2-a25 a {
    color: rgba(10, 80, 200, 0.5) !important;
    border-style: none;
    cursor: pointer; }
  .button-2-a25:hover {
    background-color: rgba(10, 80, 200, 0.15); }
  .button-2-a25:active {
    background-color: rgba(10, 80, 200, 0.25); }
  .button-2-a25:disabled {
    color: rgba(45, 60, 100, 0.5);
    border-color: rgba(234, 235, 239, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-2-a25:focus {
    outline: 0; }

.button-3 {
  color: #2D3C64 !important;
  background-color: #EAEBEF;
  border-style: none;
  border-radius: 3px;
  cursor: pointer; }
  .button-3 a {
    color: #2D3C64 !important;
    border-style: none;
    cursor: pointer; }
  .button-3:hover {
    background-color: #FFC800; }
  .button-3:active {
    background-color: #FFA000; }
  .button-3:disabled {
    color: rgba(45, 60, 100, 0.5);
    background-color: rgba(234, 235, 239, 0.5);
    cursor: default; }
  .button-3:focus {
    outline: 0; }

.button-text, .btnpt1, .btnpt2 {
  color: #0A50C8 !important;
  background-color: transparent;
  border-style: none;
  border-radius: 3px;
  cursor: pointer;
  text-align: left; }
  .button-text a, .btnpt1 a, .btnpt2 a {
    color: #0A50C8 !important;
    border-style: none;
    cursor: pointer; }
  .button-text:hover, .btnpt1:hover, .btnpt2:hover {
    color: #07347B; }
  .button-text:disabled, .btnpt1:disabled, .btnpt2:disabled {
    color: #192D5A;
    cursor: default; }
  .button-text:focus, .btnpt1:focus, .btnpt2:focus {
    outline: 0; }

.button-text2 {
  color: #192D5A !important;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
  text-align: left; }
  .button-text2 a {
    color: #192D5A !important;
    cursor: pointer; }
  .button-text2:hover {
    color: #192D5A;
    text-decoration: underline; }
  .button-text2:disabled {
    color: #192D5A;
    cursor: default; }
  .button-text2:focus {
    outline: 0; }

.button-icon, .btnicn {
  background-color: #EAEBEF;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  width: 31px;
  height: 31px;
  padding: 4px;
  box-sizing: border-box; }
  .button-icon:hover, .btnicn:hover {
    background-color: rgba(25, 45, 90, 0.2); }
  .button-icon:disabled, .btnicn:disabled {
    background-color: #F4F5F7;
    opacity: 0.5; }

.button-icon-transparent {
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  width: 31px;
  height: 31px;
  padding: 4px;
  box-sizing: border-box; }
  .button-icon-transparent:hover {
    background-color: rgba(25, 45, 90, 0.2); }
  .button-icon-transparent:disabled {
    opacity: 0.5; }

.button-icon-transparent-blue {
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  width: 31px;
  height: 31px;
  padding: 4px;
  box-sizing: border-box; }
  .button-icon-transparent-blue:hover {
    background-color: rgba(10, 80, 200, 0.15); }
  .button-icon-transparent-blue:disabled {
    background-color: #F4F5F7;
    opacity: 0.5; }

div.select .button-select, div.search .button-select {
  color: #2D3C64 !important;
  background-color: #EAEBEF;
  border: 1px solid #EAEBEF;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  padding: 4px; }
  div.select .button-select a, div.search .button-select a {
    color: #2D3C64 !important;
    border-style: none;
    cursor: pointer; }
  div.select .button-select:hover, div.search .button-select:hover {
    border: 1px solid #a6b4cf; }
  div.select .button-select:disabled, div.search .button-select:disabled {
    background-color: rgba(234, 235, 239, 0.5);
    border: 1px solid rgba(234, 235, 239, 0.5) !important;
    cursor: default; }
  div.select .button-select:focus, div.search .button-select:focus {
    border: 1px solid #0A50C8;
    outline: 0; }

div.select {
  position: relative; }
  div.select .button-select {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    width: 26px;
    height: 38px;
    top: 1px;
    right: 1px; }

div.search .button-select {
  width: 31px;
  height: 40px;
  color: #0A50C8;
  background-color: transparent; }
  div.search .button-select:active {
    background-color: rgba(10, 80, 200, 0.25); }
  div.search .button-select:hover {
    background-color: rgba(10, 80, 200, 0.15); }

.button-top-icon {
  background-color: #EAEBEF;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  width: 25px;
  height: 25px;
  box-sizing: border-box; }
  .button-top-icon:hover {
    background-color: rgba(25, 45, 90, 0.2); }
  .button-top-icon:disabled {
    background-color: #F4F5F7;
    opacity: 0.5; }

.button-rectangle-icon {
  background-color: #EAEBEF;
  border-radius: 3px;
  border-style: none;
  cursor: pointer;
  width: 35px;
  height: 30px;
  padding: 4px;
  box-sizing: border-box; }
  .button-rectangle-icon:hover {
    background-color: rgba(25, 45, 90, 0.2); }
  .button-rectangle-icon:disabled {
    background-color: #F4F5F7;
    opacity: 0.5; }

.button-option-icon {
  background-color: transparent;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  width: 31px;
  height: 31px;
  padding: 4px;
  box-sizing: border-box; }
  .button-option-icon:hover {
    background-color: rgba(25, 45, 90, 0.2); }
  .button-option-icon.selected {
    background-color: rgba(25, 45, 90, 0.2); }

/* btn color(非推奨。旧内容との互換性のために定義。) */
label.required:after {
  padding-left: 6px;
  vertical-align: bottom;
  content: "*";
  font-size: 14px;
  color: #dc3c3c; }

[hidden] {
  display: none !important; }
