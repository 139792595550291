@charset "UTF-8";
.memo-tab {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #FFFFFF; }
  .memo-tab .memo-tab-header {
    alignment: left;
    width: 100%;
    height: 130px;
    margin-bottom: 4px;
    box-shadow: 0 4px 0 rgba(0, 0, 0, 0.2), inset 0 -1px rgba(25, 45, 90, 0.1);
    background-color: #FFFFFF; }
    .memo-tab .memo-tab-header .search {
      clear: left;
      flex-grow: 1;
      display: flex;
      height: 40px;
      margin: 0 15px 9px 15px; }
      .memo-tab .memo-tab-header .search > input {
        flex-grow: 1;
        width: 200px;
        padding: 1px 8px 0 8px;
        font-size: 14px;
        height: 40px;
        box-sizing: border-box;
        border-radius: 3px 0 0 3px; }
      .memo-tab .memo-tab-header .search > button {
        min-width: initial;
        height: 40px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px; }
        .memo-tab .memo-tab-header .search > button > div {
          margin: auto; }
    .memo-tab .memo-tab-header .search-bottom {
      clear: left;
      width: 100%;
      margin-left: 0;
      height: 70px; }
      .memo-tab .memo-tab-header .search-bottom .memo_filter {
        float: left;
        display: flex;
        flex-direction: row;
        height: 24px;
        align-items: center; }
        .memo-tab .memo-tab-header .search-bottom .memo_filter div.check {
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          width: 20px;
          height: 20px;
          margin-left: 15px;
          margin-right: 4px;
          box-sizing: border-box;
          background-color: #EAEBEF;
          border-radius: 3px;
          content: '';
          text-align: center;
          line-height: 20px; }
        .memo-tab .memo-tab-header .search-bottom .memo_filter label:hover div.check::before {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
        .memo-tab .memo-tab-header .search-bottom .memo_filter label:active div.check {
          content: '✔';
          color: rgba(25, 45, 90, 0.5); }
        .memo-tab .memo-tab-header .search-bottom .memo_filter input[type="checkbox"] {
          display: none; }
          .memo-tab .memo-tab-header .search-bottom .memo_filter input[type="checkbox"]:checked + div.check {
            background-color: #FFC800; }
            .memo-tab .memo-tab-header .search-bottom .memo_filter input[type="checkbox"]:checked + div.check::before {
              color: #192D5A;
              content: '✔'; }
        .memo-tab .memo-tab-header .search-bottom .memo_filter input[type="text"] {
          box-sizing: border-box;
          border-top: none !important;
          border-right: none !important;
          border-left: none !important;
          border-bottom: dashed 1px #192D5A !important;
          border-radius: unset;
          background-color: transparent;
          padding: 2px;
          margin-right: 2px;
          width: 20px; }
        .memo-tab .memo-tab-header .search-bottom .memo_filter > label {
          float: left;
          font-size: 12px; }
      .memo-tab .memo-tab-header .search-bottom .searchDate {
        clear: left;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        width: 300px;
        height: 55px;
        padding-top: 8px;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0; }
        .memo-tab .memo-tab-header .search-bottom .searchDate .time {
          width: 140px;
          height: 40px; }
          .memo-tab .memo-tab-header .search-bottom .searchDate .time .meeting-timepicker {
            display: flex;
            align-items: center;
            height: 40px; }
            .memo-tab .memo-tab-header .search-bottom .searchDate .time .meeting-timepicker .select {
              width: 55px;
              height: 40px; }
              .memo-tab .memo-tab-header .search-bottom .searchDate .time .meeting-timepicker .select > select {
                width: 55px;
                height: 40px;
                align-items: center;
                padding-left: 7px; }
              .memo-tab .memo-tab-header .search-bottom .searchDate .time .meeting-timepicker .select .button-select {
                width: 20px;
                right: 1px;
                border: none; }
                .memo-tab .memo-tab-header .search-bottom .searchDate .time .meeting-timepicker .select .button-select:active {
                  border: none; }
        .memo-tab .memo-tab-header .search-bottom .searchDate .from-time {
          float: left;
          font-size: 12px;
          height: 13px;
          width: 12px;
          word-break: break-word;
          line-height: 13.8px;
          text-align: left;
          padding-top: 15px;
          padding-left: 0;
          padding-right: 2px; }
  .memo-tab .memo-tab-body {
    clear: left;
    background: #F4F5F7;
    padding: 2px 4px;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1; }
    .memo-tab .memo-tab-body .memo {
      border-radius: 4px;
      margin: 5px 5px 0 5px;
      background-color: #FFFFFF; }
      .memo-tab .memo-tab-body .memo:hover {
        background-color: #EAEBEF; }
      .memo-tab .memo-tab-body .memo .parts_memo {
        float: left;
        color: #192D5A;
        font-size: 13px;
        text-align: left;
        margin: 15px 15px;
        word-break: break-all;
        white-space: pre-wrap; }
      .memo-tab .memo-tab-body .memo .parts_bottom {
        clear: left;
        height: 30px;
        margin: 0 4px; }
        .memo-tab .memo-tab-body .memo .parts_bottom > button {
          margin-left: 10px;
          float: left; }
          .memo-tab .memo-tab-body .memo .parts_bottom > button:first-child {
            margin-left: 20px; }
        .memo-tab .memo-tab-body .memo .parts_bottom .parts_name {
          float: right;
          color: #192D5A;
          font-size: 12px;
          margin-top: 5px;
          margin-right: 40px; }
        .memo-tab .memo-tab-body .memo .parts_bottom .parts_timestamp {
          float: right;
          color: #192D5A;
          font-size: 12px;
          margin-top: 5px;
          margin-right: 13px; }
    .memo-tab .memo-tab-body .title_no_memo {
      font-size: 13px;
      margin: 8px 0 0 8px;
      text-align: center; }
  .memo-tab .memo-tab-footer {
    width: 100%;
    box-sizing: border-box;
    box-shadow: inset 0 1px rgba(25, 45, 90, 0.1);
    position: relative;
    clear: right;
    text-align: right; }
    .memo-tab .memo-tab-footer .memo-tab-footer-button-area {
      height: 60px;
      box-sizing: border-box;
      box-shadow: inset 0 1px rgba(25, 45, 90, 0.1); }
      .memo-tab .memo-tab-footer .memo-tab-footer-button-area > button {
        border: none;
        border-radius: 4px;
        font-size: 12px;
        font-weight: bold;
        cursor: pointer;
        padding: 10px 16px;
        height: 33px;
        width: 100px;
        margin-top: 12px;
        margin-right: 16px; }
