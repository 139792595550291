.meeting-editor {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; }
  .meeting-editor > .header {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #192D5A;
    background-color: #FFFFFF;
    z-index: 100;
    box-sizing: border-box;
    width: 100%;
    min-width: 1200px;
    height: 40px;
    box-shadow: inset 0 -1px #E2E3E8; }
    .meeting-editor > .header .logo {
      width: 205px;
      box-sizing: border-box;
      padding-left: 15px; }
    .meeting-editor > .header .title {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      text-overflow: ellipsis;
      font-size: 14px; }
    .meeting-editor > .header .meeting-time {
      margin: 0 15px;
      font-size: 12px;
      width: 240px;
      box-sizing: border-box; }
    .meeting-editor > .header .button-stop {
      margin: 0 15px;
      width: 100px;
      height: 24px;
      font-size: 13px;
      text-align: center;
      box-sizing: border-box; }
      .meeting-editor > .header .button-stop > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center; }
        .meeting-editor > .header .button-stop > div > span {
          margin-right: 2px; }
  .meeting-editor > .mainpane {
    -webkit-flex: 1;
    flex: 1;
    display: flex;
    background-color: #F4F5F7;
    color: #192D5A;
    box-sizing: border-box;
    width: 100%;
    min-width: 1200px;
    overflow-x: hidden;
    overflow-y: hidden; }
    .meeting-editor > .mainpane .switch {
      position: absolute;
      top: 5px;
      right: 7px; }
    .meeting-editor > .mainpane > .sidebar {
      position: relative;
      width: 205px;
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 12px; }
      .meeting-editor > .mainpane > .sidebar.closed {
        width: 54px; }
        .meeting-editor > .mainpane > .sidebar.closed .switch button > div {
          transform: rotate(180deg); }
      .meeting-editor > .mainpane > .sidebar > .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 13px;
        height: 40px;
        box-shadow: inset 0 -1px rgba(25, 45, 90, 0.1); }
        .meeting-editor > .mainpane > .sidebar > .header > .title {
          padding-left: 15px; }
      .meeting-editor > .mainpane > .sidebar > .info {
        width: 205px;
        -webkit-flex: 1;
        flex: 1;
        overflow-y: auto; }
        .meeting-editor > .mainpane > .sidebar > .info > .section {
          display: flex;
          flex-direction: column;
          border-bottom: solid 1px rgba(25, 45, 90, 0.1); }
          .meeting-editor > .mainpane > .sidebar > .info > .section > .label-area {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 40px;
            padding: 0 15px; }
            .meeting-editor > .mainpane > .sidebar > .info > .section > .label-area > .label {
              -webkit-flex: 1;
              flex: 1;
              background-position: left top;
              padding-left: 18px; }
            .meeting-editor > .mainpane > .sidebar > .info > .section > .label-area .open {
              transform: rotate(90deg); }
            .meeting-editor > .mainpane > .sidebar > .info > .section > .label-area .closed {
              transform: rotate(270deg); }
          .meeting-editor > .mainpane > .sidebar > .info > .section .data {
            display: flex;
            flex-direction: column;
            padding: 0 15px 15px 15px;
            word-break: break-all; }
            .meeting-editor > .mainpane > .sidebar > .info > .section .data.hidden {
              display: none; }
            .meeting-editor > .mainpane > .sidebar > .info > .section .data > .documents {
              display: flex;
              flex-direction: column;
              margin-bottom: 4px; }
              .meeting-editor > .mainpane > .sidebar > .info > .section .data > .documents > div {
                margin: 2px 0;
                padding: 4px;
                border: solid 1px #E2E3E8;
                border-radius: 4px;
                width: 100%;
                box-sizing: border-box; }
                .meeting-editor > .mainpane > .sidebar > .info > .section .data > .documents > div:hover {
                  background-color: #EAEBEF; }
                .meeting-editor > .mainpane > .sidebar > .info > .section .data > .documents > div:active {
                  background-color: #E2E3E8; }
              .meeting-editor > .mainpane > .sidebar > .info > .section .data > .documents > .download-document {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer; }
                .meeting-editor > .mainpane > .sidebar > .info > .section .data > .documents > .download-document > div:first-child {
                  -webkit-flex: 1;
                  flex: 1; }
            .meeting-editor > .mainpane > .sidebar > .info > .section .data > .participating-label {
              color: #1E7D2D; }
            .meeting-editor > .mainpane > .sidebar > .info > .section .data > .non-participating-label {
              color: #FFA000; }
            .meeting-editor > .mainpane > .sidebar > .info > .section .data > .member-data {
              min-height: 20px; }
            .meeting-editor > .mainpane > .sidebar > .info > .section .data > .button-download {
              padding: 0; }
            .meeting-editor > .mainpane > .sidebar > .info > .section .data .button-guest-rename {
              font-size: 13px;
              width: 100%;
              height: 30px;
              margin: 8px 0 0 0; }
      .meeting-editor > .mainpane > .sidebar .operations {
        border-top: solid 1px #E2E3E8;
        padding-bottom: 20px; }
        .meeting-editor > .mainpane > .sidebar .operations > div {
          margin: 16px;
          text-align: center;
          height: 12px; }
          .meeting-editor > .mainpane > .sidebar .operations > div.mail, .meeting-editor > .mainpane > .sidebar .operations > div.link {
            width: 100%;
            background-position: left top;
            padding-left: 18px; }
        .meeting-editor > .mainpane > .sidebar .operations .button-popup {
          width: 100%;
          font-size: 13px;
          padding: 0; }
        .meeting-editor > .mainpane > .sidebar .operations .button-edit {
          font-size: 13px;
          width: 100%;
          height: 30px; }
    .meeting-editor > .mainpane > .meeting-minute-closed {
      position: relative;
      width: 54px;
      box-sizing: border-box;
      box-shadow: inset -1px 0 rgba(25, 45, 90, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
      background-color: #FFFFFF; }
    .meeting-editor > .mainpane > .meeting-minute {
      display: flex;
      flex-direction: column;
      position: relative;
      min-width: 300px;
      -webkit-flex: 1.24;
      flex: 1.24;
      text-align: left;
      box-sizing: border-box;
      box-shadow: inset -1px 0 rgba(25, 45, 90, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
      background-color: #FFFFFF; }
      .meeting-editor > .mainpane > .meeting-minute > .header {
        height: 40px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2), inset 0 -1px rgba(25, 45, 90, 0.1);
        display: flex;
        flex-direction: row;
        align-items: center; }
        .meeting-editor > .mainpane > .meeting-minute > .header > .title {
          padding-left: 15px;
          font-size: 15px; }
        .meeting-editor > .mainpane > .meeting-minute > .header > .no-border-information {
          padding: 4px 10px;
          font-size: 12px;
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
          .meeting-editor > .mainpane > .meeting-minute > .header > .no-border-information .copy-button {
            padding: 6px; }
        .meeting-editor > .mainpane > .meeting-minute > .header > .information {
          margin-left: 13px;
          padding: 4px 10px;
          font-size: 12px;
          color: #1E7D2D;
          border: solid 1px #1E7D2D;
          border-radius: 13px;
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis; }
          .meeting-editor > .mainpane > .meeting-minute > .header > .information.editing {
            background-color: rgba(30, 125, 45, 0.1); }
      .meeting-editor > .mainpane > .meeting-minute > .background {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        line-height: 2rem;
        color: rgba(25, 45, 90, 0.7);
        pointer-events: none;
        text-align: center; }
        .meeting-editor > .mainpane > .meeting-minute > .background .hint {
          font-size: 13px;
          font-weight: bold; }
        .meeting-editor > .mainpane > .meeting-minute > .background .functions {
          display: flex;
          flex-direction: column;
          align-items: center; }
          .meeting-editor > .mainpane > .meeting-minute > .background .functions button {
            margin: 8px;
            width: 120px;
            height: 36px;
            pointer-events: auto; }
          .meeting-editor > .mainpane > .meeting-minute > .background .functions .attention {
            font-size: 11px; }
        .meeting-editor > .mainpane > .meeting-minute > .background .info {
          font-size: 12px; }
          .meeting-editor > .mainpane > .meeting-minute > .background .info span {
            font-weight: bold; }
      .meeting-editor > .mainpane > .meeting-minute .body {
        -webkit-flex: 1;
        flex: 1;
        overflow-y: auto;
        margin-top: 3px;
        padding: 1px; }
        .meeting-editor > .mainpane > .meeting-minute .body > div {
          min-height: 100%;
          box-sizing: border-box;
          word-break: break-word; }
        .meeting-editor > .mainpane > .meeting-minute .body .minute {
          padding: 8px; }
        .meeting-editor > .mainpane > .meeting-minute .body.hidden {
          background-color: #F4F4FA; }
          .meeting-editor > .mainpane > .meeting-minute .body.hidden > div {
            display: none; }
      .meeting-editor > .mainpane > .meeting-minute .operations {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 61px;
        box-sizing: border-box;
        padding: 8px 16px;
        box-shadow: inset 0 1px #E2E3E8;
        font-size: 13px; }
        .meeting-editor > .mainpane > .meeting-minute .operations .label {
          margin-bottom: 5px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .meeting-editor > .mainpane > .meeting-minute .operations .buttons {
          display: flex; }
        .meeting-editor > .mainpane > .meeting-minute .operations .label > div {
          margin: 0 4px; }
        .meeting-editor > .mainpane > .meeting-minute .operations button + button {
          margin-left: 8px; }
        .meeting-editor > .mainpane > .meeting-minute .operations .download-minute {
          display: flex;
          flex-direction: row;
          align-items: center; }
          .meeting-editor > .mainpane > .meeting-minute .operations .download-minute .button-download {
            padding: 8px;
            cursor: pointer; }
        .meeting-editor > .mainpane > .meeting-minute .operations .button-minute {
          padding: 8px 8px;
          width: 88px;
          box-sizing: border-box; }
    .meeting-editor > .mainpane > .memo-closed {
      position: relative;
      width: 54px;
      box-sizing: border-box;
      box-shadow: inset -1px 0 rgba(25, 45, 90, 0.1), 0 0 4px rgba(0, 0, 0, 0.2);
      background-color: #FFFFFF; }
    .meeting-editor > .mainpane > .memo {
      display: flex;
      flex-direction: column;
      position: relative;
      min-width: 300px;
      -webkit-flex: 1;
      flex: 1;
      text-align: left;
      box-sizing: border-box;
      box-shadow: inset -1px 0 rgba(25, 45, 90, 0.1), 0 4 4px rgba(0, 0, 0, 0.2);
      background-color: #FFFFFF; }
      .meeting-editor > .mainpane > .memo.closed {
        width: 54px;
        background-color: #FFFFFF; }
        .meeting-editor > .mainpane > .memo.closed .switch button > div {
          transform: rotate(180deg); }
      .meeting-editor > .mainpane > .memo > .header {
        height: 145px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), inset 0 -1px rgba(25, 45, 90, 0.1);
        background-color: #FFFFFF; }
        .meeting-editor > .mainpane > .memo > .header > .title {
          padding-top: 14px;
          padding-left: 15px;
          font-size: 15px; }
    .meeting-editor > .mainpane > .comment {
      -webkit-flex: 1.11;
      flex: 1.11;
      min-width: 300px;
      box-sizing: border-box;
      height: 100%; }
    .meeting-editor > .mainpane .hidden {
      display: none !important; }
    .meeting-editor > .mainpane .memo-title {
      padding-top: 12px;
      padding-left: 15px;
      padding-bottom: 8px;
      font-size: 15px;
      float: left;
      margin: 0 0 14px 0; }

.meeting-editor-emails-popup,
.meeting-editor-url-popup {
  height: 230px; }
  .meeting-editor-emails-popup textarea,
  .meeting-editor-url-popup textarea {
    padding: 4px;
    width: 300px;
    height: 100px;
    line-height: 1.5em;
    font-size: 13px; }
  .meeting-editor-emails-popup > .buttons .copy,
  .meeting-editor-url-popup > .buttons .copy {
    width: 160px; }

.ipad .meeting-editor > .header, .ipad .meeting-editor .mainpane, .ipad .meeting-editor .pane {
  min-width: initial; }
