.my-groups {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #FFFFFF;
  color: #192D5A;
  display: flex;
  flex-direction: row; }
  .my-groups div.my-groups-left {
    width: 315px;
    height: 100%;
    box-sizing: border-box;
    background-color: #F4F5F7;
    box-shadow: inset -1px 0 #E2E3E8; }
    .my-groups div.my-groups-left div.title {
      font-size: 18px; }
    .my-groups div.my-groups-left div.no-data {
      text-align: center; }
      .my-groups div.my-groups-left div.no-data div.title {
        padding-top: 100px; }
      .my-groups div.my-groups-left div.no-data div.explanation {
        padding: 20px 55px 0 55px; }
      .my-groups div.my-groups-left div.no-data div.buttons {
        padding-top: 20px; }
    .my-groups div.my-groups-left div.data {
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box; }
      .my-groups div.my-groups-left div.data div.header {
        margin: 1px;
        background-color: #FFFFFF;
        align-items: center;
        display: flex;
        height: 50px;
        justify-content: space-between;
        padding: 0 15px;
        box-shadow: 0 2px 4px rgba(25, 45, 90, 0.2); }
      .my-groups div.my-groups-left div.data div.detail {
        margin: 3px 1px 0 1px;
        font-size: 13px;
        -webkit-flex: 1;
        flex: 1;
        overflow-y: auto; }
        .my-groups div.my-groups-left div.data div.detail table {
          width: 100%;
          table-layout: fixed;
          border-collapse: collapse;
          line-height: 15px; }
          .my-groups div.my-groups-left div.data div.detail table tbody tr {
            box-sizing: border-box;
            width: 100%;
            height: 70px;
            cursor: pointer;
            border-bottom: 1px solid #E2E3E8; }
            .my-groups div.my-groups-left div.data div.detail table tbody tr.active td {
              background-color: #E2E3E8; }
            .my-groups div.my-groups-left div.data div.detail table tbody tr:hover td {
              background-color: #EAEBEF; }
          .my-groups div.my-groups-left div.data div.detail table tbody td {
            background: #FFFFFF;
            word-break: break-all; }
            .my-groups div.my-groups-left div.data div.detail table tbody td.name {
              padding-left: 15px;
              width: 200px;
              box-sizing: border-box;
              height: 70px; }
            .my-groups div.my-groups-left div.data div.detail table tbody td.counts {
              width: 60px;
              text-align: center; }
              .my-groups div.my-groups-left div.data div.detail table tbody td.counts > span {
                display: table-cell;
                height: 20px;
                width: 50px;
                font-size: 11px;
                vertical-align: middle;
                background-color: rgba(10, 80, 200, 0.15);
                border-radius: 2px; }
            .my-groups div.my-groups-left div.data div.detail table tbody td div.option-menu {
              position: relative;
              margin: 0 auto;
              -moz-user-select: none;
              -webkit-user-select: none;
              -ms-user-select: none; }
              .my-groups div.my-groups-left div.data div.detail table tbody td div.option-menu div.menus {
                border-radius: 4px;
                position: absolute;
                flex-direction: column;
                top: 22px;
                left: -54px;
                width: 87px;
                height: 63px;
                z-index: 20;
                background-color: #FFFFFF;
                color: #2D3C64;
                font-size: 12px;
                text-align: center;
                box-shadow: 0 4px 12px rgba(25, 45, 90, 0.25), 0 0 4px rgba(25, 45, 90, 0.15); }
                .my-groups div.my-groups-left div.data div.detail table tbody td div.option-menu div.menus > div {
                  height: 50%;
                  width: 100%;
                  padding-top: 8px;
                  box-sizing: border-box; }
                  .my-groups div.my-groups-left div.data div.detail table tbody td div.option-menu div.menus > div:hover {
                    background-color: #F4F5F7; }
                .my-groups div.my-groups-left div.data div.detail table tbody td div.option-menu div.menus div.edit-group {
                  border-radius: 4px 4px 0 0;
                  border-bottom: 1px solid #F4F5F7; }
                .my-groups div.my-groups-left div.data div.detail table tbody td div.option-menu div.menus div.delete-group {
                  border-radius: 0 0 4px 4px; }
    .my-groups div.my-groups-left button {
      font-size: 13px;
      height: 30px;
      border: none;
      border-radius: 4px;
      padding: 10px; }
  .my-groups div.my-groups-right {
    -webkit-flex: 1;
    flex: 1;
    height: 100%;
    box-sizing: border-box; }
