.mymemo-popup {
  width: 500px;
  height: 420px; }
  .mymemo-popup .title {
    padding-left: 24px;
    height: 60px;
    background-color: #0A46A5;
    color: #FFFFFF;
    font-size: 24px; }
  .mymemo-popup .inner {
    padding: 0;
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    -webkit-flex: 1;
    flex: 1;
    text-align: left; }
    .mymemo-popup .inner .form .content_memo {
      text-align: left;
      display: flex;
      align-items: center;
      margin: 16px 12px; }
      .mymemo-popup .inner .form .content_memo .item_name_memo {
        width: 95%;
        color: #192D5A;
        padding: 4px 0 8px 0; }
      .mymemo-popup .inner .form .content_memo .input_memo {
        width: 90%;
        height: 120px;
        word-wrap: break-word;
        padding: 10px;
        font-size: 15px;
        resize: none;
        float: right; }
      .mymemo-popup .inner .form .content_memo .input_value {
        color: #8c8c8c;
        margin-top: 8px;
        padding-right: 16px;
        margin-left: auto; }
    .mymemo-popup .inner .form div.content_etc {
      width: 90%;
      float: left;
      align-items: center;
      display: flex;
      flex-direction: row;
      border-top-width: 1px;
      border-top-style: dotted;
      border-top-color: #CDCDCD;
      margin: 0 5% 0 5%; }
      .mymemo-popup .inner .form div.content_etc div.content_date {
        box-sizing: border-box;
        text-align: left;
        margin: 8px 0;
        padding: 0 8px;
        width: 50%;
        float: left; }
        .mymemo-popup .inner .form div.content_etc div.content_date .item-title {
          color: #192D5A;
          margin: 8px 0; }
        .mymemo-popup .inner .form div.content_etc div.content_date .now-date-time {
          margin: 8px 0;
          font-size: 14px; }
      .mymemo-popup .inner .form div.content_etc div.content_publiced {
        box-sizing: border-box;
        text-align: left;
        margin: 10px 8px;
        padding: 0 20px;
        width: 50%; }
        .mymemo-popup .inner .form div.content_etc div.content_publiced .item-title {
          color: #192D5A;
          margin: 12px 0 0 0; }
        .mymemo-popup .inner .form div.content_etc div.content_publiced .radio_button {
          margin: 6px 0;
          width: 16px;
          height: 16px;
          float: left; }
          .mymemo-popup .inner .form div.content_etc div.content_publiced .radio_button input[type="radio"]:checked + .radio {
            color: #ffffff;
            background-color: #31A9EE; }
        .mymemo-popup .inner .form div.content_etc div.content_publiced .label_undisabled {
          margin: 8px 15px 8px 4px;
          float: left;
          font-size: 14px;
          color: #192D5A; }
        .mymemo-popup .inner .form div.content_etc div.content_publiced .label_disabled {
          margin: 8px 15px 8px 4px;
          float: left;
          font-size: 14px;
          color: grey; }
  .mymemo-popup > .buttons {
    margin: 0;
    padding: 16px;
    height: 68px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px; }
    .mymemo-popup > .buttons button {
      margin: 0 8px;
      width: 120px;
      height: 40px;
      border: none;
      border-radius: 4px;
      font-size: 13px;
      box-sizing: border-box; }
