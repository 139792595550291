.calculation-dictionaries {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row; }
  .calculation-dictionaries div.calculation-dictionaries-main {
    box-sizing: border-box;
    width: 350px; }
    .calculation-dictionaries div.calculation-dictionaries-main .calculation-dictionaries-header {
      box-sizing: border-box;
      width: 100%;
      margin: 8px 0;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .calculation-dictionaries div.calculation-dictionaries-main .calculation-dictionaries-header .title {
        padding: 8px 16px;
        color: #113355; }
      .calculation-dictionaries div.calculation-dictionaries-main .calculation-dictionaries-header > .title {
        padding: 8px 8px 8px 16px;
        width: 400px;
        font-size: 20px;
        box-sizing: border-box;
        text-align: left; }
    .calculation-dictionaries div.calculation-dictionaries-main table {
      box-sizing: border-box;
      width: 100%;
      text-align: left;
      border-collapse: separate;
      border-spacing: inherit;
      font-size: 12px;
      table-layout: fixed;
      overflow: hidden; }
      .calculation-dictionaries div.calculation-dictionaries-main table thead {
        background-color: rgba(1, 81, 204, 0.15); }
        .calculation-dictionaries div.calculation-dictionaries-main table thead th {
          padding: 8px;
          text-align: center;
          color: #07347B; }
      .calculation-dictionaries div.calculation-dictionaries-main table tbody tr {
        padding: 3px;
        height: 60px;
        cursor: pointer;
        background: #FFFFFF; }
        .calculation-dictionaries div.calculation-dictionaries-main table tbody tr.current {
          background: #E2E3E8; }
        .calculation-dictionaries div.calculation-dictionaries-main table tbody tr:hover td {
          background-color: #EAEBEF; }
      .calculation-dictionaries div.calculation-dictionaries-main table tbody td {
        box-sizing: border-box;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent; }
        .calculation-dictionaries div.calculation-dictionaries-main table tbody td:first-child {
          border-left: 4px solid transparent; }
        .calculation-dictionaries div.calculation-dictionaries-main table tbody td:last-child {
          border-right: 4px solid transparent; }
        .calculation-dictionaries div.calculation-dictionaries-main table tbody td div.count {
          text-align: right;
          width: 46px; }
      .calculation-dictionaries div.calculation-dictionaries-main table tbody td {
        padding: 8px;
        color: #192D5A; }
    .calculation-dictionaries div.calculation-dictionaries-main th.name, .calculation-dictionaries div.calculation-dictionaries-main td.name {
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      word-break: break-all; }
    .calculation-dictionaries div.calculation-dictionaries-main th.num_of_words, .calculation-dictionaries div.calculation-dictionaries-main td.num_of_words {
      text-align: left;
      width: 80px; }
    .calculation-dictionaries div.calculation-dictionaries-main th.functions, .calculation-dictionaries div.calculation-dictionaries-main td.functions {
      width: 32px;
      text-align: left; }
  .calculation-dictionaries .calculation-dictionary-words {
    -webkit-flex: 1;
    flex: 1; }
