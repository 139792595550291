.meeting-status {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0; }
  .meeting-status > div {
    box-sizing: border-box;
    min-width: 73px;
    padding: 1px 20px;
    text-align: center;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 11px;
    font-weight: bold; }
  .meeting-status .ended {
    background-color: rgba(25, 45, 90, 0.2);
    color: #192D5A; }
  .meeting-status .started {
    background-color: rgba(255, 170, 0, 0.3);
    color: #FFA000; }
