.meeting-guest-rename-popup {
  width: 500px;
  height: 350px; }
  .meeting-guest-rename-popup .title {
    padding-left: 24px;
    height: 60px;
    background-color: #0A46A5;
    color: #FFFFFF;
    font-size: 24px; }
  .meeting-guest-rename-popup .inner {
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    -webkit-flex: 1;
    flex: 1;
    text-align: left;
    padding: 20px 28px;
    color: #192D5A; }
    .meeting-guest-rename-popup .inner form input, .meeting-guest-rename-popup .inner form select, .meeting-guest-rename-popup .inner form option {
      margin: 0;
      padding: 0 10px 6px 10px;
      height: 40px;
      width: 100%;
      font-size: 14px;
      box-sizing: border-box; }
    .meeting-guest-rename-popup .inner form input, .meeting-guest-rename-popup .inner form label {
      margin-bottom: 8px; }
    .meeting-guest-rename-popup .inner form select {
      margin-bottom: 24px; }
  .meeting-guest-rename-popup > .buttons {
    margin: 0;
    padding: 16px;
    height: 68px;
    background-color: white;
    box-sizing: border-box;
    border-radius: 0 0 4px 4px; }
    .meeting-guest-rename-popup > .buttons button {
      margin: 0 8px;
      width: 120px;
      height: 40px;
      border: none;
      border-radius: 4px;
      font-size: 13px;
      box-sizing: border-box; }
