.customer-settings {
  font-size: 1em;
  background-color: white;
  padding-top: 25px; }
  .customer-settings .body {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column; }
  .customer-settings .authority-area {
    text-align: left;
    margin-left: 60px; }
    .customer-settings .authority-area .authority-title {
      font-size: 1.2em; }
    .customer-settings .authority-area .authority-table {
      margin-left: 60px; }
    .customer-settings .authority-area .buttons {
      text-align: left;
      margin-left: 400px; }
    .customer-settings .authority-area .buttons button {
      margin: 0 8px;
      width: 120px;
      height: 30px;
      border: none;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      box-sizing: border-box; }
  .customer-settings table {
    margin: 1em 0 1em 1px;
    padding: 0;
    border: 1px solid #878B92;
    border-spacing: 0;
    border-collapse: collapse; }
    .customer-settings table img {
      border: 0;
      margin: .5em; }
  .customer-settings .th-column2 {
    border-left: none; }
  .customer-settings td {
    color: #2e3c49;
    border-bottom: 1px solid #878B92;
    border-left: 1px solid #878B92;
    padding: .3em 1em;
    text-align: center;
    /*min-width: 100px;*/ }
  .customer-settings td:first-child {
    text-align: left; }
  .customer-settings td:last-child {
    border-left: none; }
  .customer-settings th:last-child {
    border-left: none; }
  .customer-settings th {
    font-weight: normal;
    color: #678197;
    text-align: left;
    border-bottom: 1px solid #878B92;
    border-left: 1px solid #878B92;
    padding: .3em 1em; }
