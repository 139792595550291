.meetings {
  background-color: #F4F5F7;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  box-sizing: border-box; }
  .meetings .meetings-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px #e2e2e4;
    font-size: 14px;
    background-color: #FFFFFF;
    height: 50px; }
    .meetings .meetings-header .meetings-title {
      color: #192D5A;
      padding: 16px 0 18px 15px;
      width: 90px;
      box-sizing: border-box;
      text-align: left;
      font-size: 16px;
      font-weight: bold; }
    .meetings .meetings-header button.create {
      padding: 9px 10px;
      font-weight: bold;
      font-size: 13px;
      margin-right: auto;
      margin-left: 20px; }
    .meetings .meetings-header .search {
      display: flex;
      margin: 0 15px;
      width: 279px; }
      .meetings .meetings-header .search > input {
        width: 249px;
        margin: 0;
        padding: 0 8px;
        border: 1px solid #EEEEEE;
        border-radius: 3px 0 0 3px;
        flex-grow: 1;
        box-sizing: border-box;
        font-size: 14px;
        height: 40px; }
      .meetings .meetings-header .search > button {
        width: 30px;
        margin: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        height: 40px; }
    .meetings .meetings-header button {
      height: 30px; }
    .meetings .meetings-header .monthly > div + div {
      margin-left: 24px; }
    .meetings .meetings-header .monthly > div span {
      margin: 0 8px;
      font-size: 1.8em;
      font-weight: bold; }
  .meetings .meetings-info {
    padding-top: 40px; }
  .meetings .meetings-detail {
    -webkit-flex: 1;
    flex: 1;
    overflow-y: auto; }
  .meetings table {
    text-align: left;
    border-collapse: separate;
    font-size: 12px;
    table-layout: fixed;
    overflow: hidden;
    margin: 0 15px;
    border-spacing: 0 5px; }
    .meetings table thead {
      background-color: rgba(1, 81, 204, 0.15); }
      .meetings table thead th {
        padding: 8px;
        text-align: left;
        color: #07347B;
        background-color: rgba(1, 81, 204, 0.15); }
        .meetings table thead th:first-child {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px; }
        .meetings table thead th:last-child {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px; }
    .meetings table tbody tr {
      height: 70px;
      cursor: pointer;
      background-color: #FFFFFF; }
    .meetings table tbody td {
      background-color: #FFFFFF;
      border-color: #FFFFFF;
      overflow-wrap: break-word;
      padding: 0 10px;
      color: #192D5A; }
      .meetings table tbody td:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px; }
      .meetings table tbody td:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px; }
    .meetings table tbody tr:hover td {
      background-color: #EAEBEF; }
    .meetings table tbody tr:active td {
      background-color: #EEEFF2; }
    .meetings table tbody th.title, .meetings table tbody td.title {
      width: 25%;
      position: relative;
      word-break: break-all;
      padding: 24px 10px; }
    .meetings table tbody th.date, .meetings table tbody td.date {
      width: 15%;
      text-align: center; }
    .meetings table tbody td.date .date, .meetings table tbody td.room .room {
      display: table-cell;
      padding-left: 5px;
      vertical-align: middle; }
    .meetings table tbody .icon {
      display: table-cell;
      width: 18px;
      height: 18px;
      background-position: center;
      background-repeat: no-repeat; }
    .meetings table tbody td.date .icon {
      background-image: url("../images/clock.svg"); }
    .meetings table tbody td.room .icon {
      background-image: url("../images/place.svg"); }
    .meetings table tbody div.date > div {
      display: flex; }
    .meetings table tbody td.date div.date > div + div {
      margin-top: 8px; }
    .meetings table tbody th.room, .meetings table tbody td.room {
      width: 15%;
      word-break: break-all; }
    .meetings table tbody th.numofmembers, .meetings table tbody td.numofmembers {
      width: 7.5%; }
    .meetings table tbody th.editor, .meetings table tbody td.editor {
      width: 12.5%; }
    .meetings table tbody th.updatedat, .meetings table tbody td.updatedat {
      width: 10%; }
    .meetings table tbody td.updatedat div + div {
      margin-top: 8px; }
    .meetings table tbody th.usagetime, .meetings table tbody td.usagetime {
      width: 7.5%; }
    .meetings table tbody th.usageamount, .meetings table tbody td.usageamount {
      width: 7.5%; }
    .meetings table tbody td.usageamount {
      text-align: left; }
    .meetings table tbody td.functions {
      text-align: right; }
      .meetings table tbody td.functions > div {
        visibility: visible;
        display: flex;
        justify-content: flex-end; }
      .meetings table tbody td.functions a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none; }
      .meetings table tbody td.functions button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: none;
        background-color: transparent;
        margin-left: 2px; }
    .meetings table tbody th.functions {
      text-align: right; }

.ipad .meetings .header > .title {
  width: 120px; }

.ipad .meetings .header .operations .search {
  width: 238px; }

.ipad .meetings .header .monthly > div span {
  font-size: 1.3em; }

.ipad .meetings .header .monthly > div + div {
  margin-left: 16px; }

.ipad .meetings td.functions button > div {
  margin: 0 auto; }
