@charset "UTF-8";
.meeting-popup {
  width: 790px;
  height: calc(100vh - 100px); }
  .meeting-popup .error {
    padding-top: 5px; }
  .meeting-popup > .title {
    padding-left: 50px;
    height: 60px;
    background-color: #0A46A5;
    color: #FFFFFF;
    font-size: 24px; }
  .meeting-popup > .inner {
    padding: 0;
    overflow: hidden; }
  .meeting-popup > .buttons {
    height: 80px;
    padding: 0 50px 0 50px; }
    .meeting-popup > .buttons button {
      height: 40px; }
  .meeting-popup label {
    display: block;
    height: 20px;
    font-weight: bold; }
  .meeting-popup form {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    color: #192D5A;
    font-size: 13px;
    display: flex;
    flex-direction: row;
    padding: 26px 0 29px 0; }
    .meeting-popup form > div {
      text-align: left;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
      overflow-y: auto; }
      .meeting-popup form > div.main-area {
        box-sizing: border-box;
        height: 100%;
        width: 520px;
        border-right: 2px solid #EAEBEF;
        overflow-y: auto; }
        .meeting-popup form > div.main-area > div {
          padding: 0 30px 0 50px;
          box-sizing: border-box;
          width: 100%; }
        .meeting-popup form > div.main-area div.message-billing .warning {
          height: 45px; }
        .meeting-popup form > div.main-area div.message-auto-off .warning {
          height: 30px; }
        .meeting-popup form > div.main-area div.end-check {
          margin-top: 7px; }
        .meeting-popup form > div.main-area div.warning {
          margin-top: 7px; }
        .meeting-popup form > div.main-area div.desc {
          margin-top: 8px;
          color: rgba(25, 45, 90, 0.7);
          font-size: 13px; }
        .meeting-popup form > div.main-area div.minutes-type, .meeting-popup form > div.main-area div.room, .meeting-popup form > div.main-area div.engine, .meeting-popup form > div.main-area div.dictionary {
          margin-top: 26px; }
          .meeting-popup form > div.main-area div.minutes-type label, .meeting-popup form > div.main-area div.room label, .meeting-popup form > div.main-area div.engine label, .meeting-popup form > div.main-area div.dictionary label {
            width: 100%;
            background-position: left top;
            padding-left: 18px; }
        .meeting-popup form > div.main-area div.attach-documents, .meeting-popup form > div.main-area div.agendas {
          margin-top: 10px; }
        .meeting-popup form > div.main-area div.start-date {
          display: flex;
          flex-direction: row;
          margin-top: 27px; }
          .meeting-popup form > div.main-area div.start-date div.date {
            width: 130px;
            margin-right: 10px; }
            .meeting-popup form > div.main-area div.start-date div.date label {
              width: 100%;
              background-position: left top;
              padding-left: 18px; }
          .meeting-popup form > div.main-area div.start-date div.time {
            width: 134px; }
          .meeting-popup form > div.main-area div.start-date div.tilde {
            position: relative;
            width: 20px;
            text-align: center; }
            .meeting-popup form > div.main-area div.start-date div.tilde > span {
              margin-top: 0.5em;
              position: absolute;
              box-sizing: border-box;
              top: 50%;
              left: 50%;
              transform: translateY(-50%) translateX(-50%); }
          .meeting-popup form > div.main-area div.start-date > div {
            display: flex;
            flex-direction: column; }
      .meeting-popup form > div.members-area {
        -webkit-flex: 1;
        flex: 1;
        height: 100%;
        width: 270px;
        box-sizing: border-box; }
        .meeting-popup form > div.members-area > div {
          padding-left: 30px;
          padding-right: 50px; }
          .meeting-popup form > div.members-area > div .member-list > div {
            height: 40px;
            padding-bottom: 8px; }
        .meeting-popup form > div.members-area > div > label {
          width: 100%;
          height: 20px;
          padding: 0 0 0 18px;
          background-position: left top; }
        .meeting-popup form > div.members-area > div .checkbox_label {
          width: 100%;
          height: 20px;
          padding: 10px 0 0 0;
          background-position: left top; }
        .meeting-popup form > div.members-area > div .send_mail_note {
          font-size: 10px;
          padding: 10px 0; }
        .meeting-popup form > div.members-area > div .members > ul > li {
          background-color: #FFFFFF;
          border: 1.5px solid #E2E3E8; }
    .meeting-popup form input, .meeting-popup form select, .meeting-popup form option {
      margin: 0;
      padding-left: 10px;
      padding-right: 10px;
      height: 40px;
      width: 100%;
      font-size: 14px;
      box-sizing: border-box; }
    .meeting-popup form label.checkbox_label {
      width: auto;
      display: flex;
      align-items: center;
      padding: 0; }
    .meeting-popup form label div.check {
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      margin-left: 4px;
      margin-right: 4px;
      box-sizing: border-box;
      background-color: #EAEBEF;
      border-radius: 3px;
      content: '';
      float: left;
      text-align: center;
      line-height: 20px; }
    .meeting-popup form div label[disabled] {
      color: silver; }
    .meeting-popup form label:hover:not([disabled]) div.check::before {
      content: '✔';
      color: rgba(25, 45, 90, 0.5); }
    .meeting-popup form label:active div.check {
      content: '✔';
      color: rgba(25, 45, 90, 0.5); }
    .meeting-popup form div input[type="checkbox"] {
      display: none; }
      .meeting-popup form div input[type="checkbox"]:checked + div.check {
        background-color: #FFC800; }
        .meeting-popup form div input[type="checkbox"]:checked + div.check::before {
          color: #192D5A;
          content: '✔'; }
    .meeting-popup form div div.send_mail_note {
      font-size: 10px;
      padding: 10px 0 10px 25px; }
  .meeting-popup input[type="file"] {
    display: none; }
  .meeting-popup .attach-documents {
    box-sizing: border-box;
    width: 100%; }
    .meeting-popup .attach-documents .attachment {
      margin: 5px auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center; }
      .meeting-popup .attach-documents .attachment .drag-and-drop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 72px;
        box-sizing: border-box;
        border: 2px dashed #E2E3E8; }
        .meeting-popup .attach-documents .attachment .drag-and-drop.enter {
          border: 2px dashed black;
          color: black; }
        .meeting-popup .attach-documents .attachment .drag-and-drop > p {
          margin: 8px 0; }
        .meeting-popup .attach-documents .attachment .drag-and-drop > span {
          margin-top: 8px; }
    .meeting-popup .attach-documents .select-file {
      margin-bottom: 8px;
      color: #444444; }
      .meeting-popup .attach-documents .select-file > div {
        margin-bottom: 8px; }
      .meeting-popup .attach-documents .select-file button {
        padding: 4px 8px;
        border-radius: 4px;
        font-weight: bold; }
    .meeting-popup .attach-documents .documents {
      margin: 0;
      padding: 0;
      color: black;
      font-size: 0.8em; }
  .meeting-popup ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .meeting-popup .documents ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .meeting-popup .members ul {
    text-align: center; }
  .meeting-popup ul li {
    margin-left: 10px;
    padding: 3px;
    display: flex;
    align-items: center;
    border-radius: 4px; }
  .meeting-popup .members li, .meeting-popup .submitter li {
    padding: 0 0 0 8px;
    height: 24px;
    background-color: #EEEEEE;
    cursor: default; }
  .meeting-popup .documents li {
    padding: 0 0 0 8px;
    height: 24px;
    background-color: #EEEEEE;
    cursor: default;
    text-align: left;
    margin: 8px 4px 0 4px; }
  .meeting-popup .members li {
    margin: 0 8px 8px 0; }
  .meeting-popup .members li .close, .meeting-popup .submitter li .close, .meeting-popup .documents li .close {
    margin: 0 0 0 8px;
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    border-radius: 4px; }
  .meeting-popup .members li:hover .close, .meeting-popup .submitter li:hover .close, .meeting-popup .documents li:hover .close {
    display: block;
    cursor: pointer; }
  .meeting-popup .meeting-timepicker {
    display: flex;
    align-items: center; }
    .meeting-popup .meeting-timepicker div.select {
      position: relative; }
    .meeting-popup .meeting-timepicker div.hidden {
      display: none; }
    .meeting-popup .meeting-timepicker div.select + div {
      margin-left: 0; }
    .meeting-popup .meeting-timepicker div.select select {
      min-width: 60px; }
    .meeting-popup .meeting-timepicker div.select input {
      position: absolute;
      width: 36px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
  .meeting-popup div.agendas {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    background-color: #F4F5F7; }
    .meeting-popup div.agendas button.button-add {
      margin: 10px 0;
      width: 116px;
      height: 30px; }
      .meeting-popup div.agendas button.button-add > div {
        padding-right: 30px;
        background-position: right center; }
    .meeting-popup div.agendas span.disabled {
      color: silver; }
    .meeting-popup div.agendas div.agenda {
      box-sizing: border-box;
      width: 100%;
      background: #FFFFFF;
      border-radius: 4px;
      margin: 0 0 10px;
      color: #192D5A;
      font-size: 13px;
      box-shadow: 0 1px 3px rgba(25, 45, 90, 0.2); }
      .meeting-popup div.agendas div.agenda div.agenda-header {
        box-sizing: border-box;
        width: 100%;
        height: 40px;
        padding: 8px 0 8px 15px;
        border-bottom: 1px solid #f6f6fb;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
        .meeting-popup div.agendas div.agenda div.agenda-header div.agenda-index {
          float: left;
          text-align: left;
          padding-top: 8px; }
        .meeting-popup div.agendas div.agenda div.agenda-header div.agenda-icons {
          display: flex;
          flex-direction: row;
          justify-content: center; }
          .meeting-popup div.agendas div.agenda div.agenda-header div.agenda-icons > .agenda-icon {
            width: 43px;
            border-left: 1px solid rgba(7, 52, 123, 0.1);
            display: flex;
            justify-content: center; }
            .meeting-popup div.agendas div.agenda div.agenda-header div.agenda-icons > .agenda-icon .up {
              transform: rotate(180deg); }
            .meeting-popup div.agendas div.agenda div.agenda-header div.agenda-icons > .agenda-icon > button .icon-trash-large {
              background-size: 16px 16px; }
          .meeting-popup div.agendas div.agenda div.agenda-header div.agenda-icons > div:first-child {
            border-left: none; }
      .meeting-popup div.agendas div.agenda div.agenda-body {
        padding: 15px 0 0 0;
        font-size: 12px; }
        .meeting-popup div.agendas div.agenda div.agenda-body > div {
          padding: 0 15px 15px; }
        .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-title > label {
          height: 18px; }
        .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-title > textarea {
          display: flex;
          align-items: center; }
        .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-row {
          display: flex;
          flex-direction: row; }
        .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-presenter {
          -webkit-flex: 1;
          flex: 1; }
          .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-presenter > label {
            height: 18px; }
          .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-presenter > textarea {
            display: flex;
            align-items: center; }
        .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-time-required {
          padding-left: 10px;
          width: 120px; }
          .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-time-required div label {
            height: 18px; }
          .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-time-required div > textarea {
            display: flex;
            align-items: center; }
          .meeting-popup div.agendas div.agenda div.agenda-body div.agenda-time-required input {
            margin-right: 4px;
            padding-right: 10px;
            text-align: right;
            width: 100px; }
        .meeting-popup div.agendas div.agenda div.agenda-body input {
          margin-top: 2px;
          padding-left: 10px;
          height: 40px;
          border: 1px solid #EEEEEE;
          border-radius: 4px;
          font-size: 14px;
          box-sizing: border-box; }
        .meeting-popup div.agendas div.agenda div.agenda-body textarea.target {
          width: 100%;
          margin-top: 2px;
          padding-left: 10px;
          height: 40px;
          line-height: 20px;
          font-size: 14px;
          box-sizing: border-box;
          word-break: break-all;
          overflow: hidden;
          resize: none;
          padding-top: 8px; }

.rc-time-picker-panel ul {
  display: block; }

.icn.close {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 2px;
  overflow: hidden;
  cursor: pointer; }
  .icn.close:hover {
    background: #DBDBDB; }
  .icn.close:active {
    background: #C9C9C9; }
  .icn.close:hover::before, .icn.close:hover::after {
    background: #9E9E9E; }
  .icn.close:active::before, .icn.close:active::after {
    background: #8C8C8C; }
  .icn.close::before, .icn.close::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 50%;
    top: 50%;
    left: 25%;
    margin-top: -1px;
    background: #C2C2C2; }
  .icn.close::before {
    transform: rotate(45deg); }
  .icn.close::after {
    transform: rotate(-45deg); }
  .icn.close.big {
    transform: scale(3); }
  .icn.close.hairline::before, .icn.close.hairline::after {
    height: 1px; }
  .icn.close.thick::before, .icn.close.thick::after {
    height: 4px;
    margin-top: -2px; }
  .icn.close.black::before, .icn.close.black::after {
    height: 8px;
    margin-top: -4px; }
  .icn.close.heavy::before, .icn.close.heavy::after {
    height: 12px;
    margin-top: -6px; }
  .icn.close.pointy::Before, .icn.close.pointy::after {
    width: 200%;
    left: -50%; }
  .icn.close.rounded::before, .icn.close.rounded::after {
    border-radius: 5px; }
  .icn.close.blades::before, .icn.close.blades::after {
    border-radius: 5px 0; }
  .icn.close.warp::before, .icn.close.warp::after {
    border-radius: 120% 0; }
  .icn.close.fat::before, .icn.close.fat::after {
    border-radius: 100%; }

.ipad .meeting-popup .inner::-webkit-scrollbar {
  width: 15px; }

.ipad .meeting-popup .inner::-webkit-scrollbar-track {
  background: #dddddd; }

.ipad .meeting-popup .inner::-webkit-scrollbar-thumb {
  background: #2cc2e4; }

.ipad .meeting-popup form div.select .icon > div {
  margin: 0 auto; }
