.account-popup {
  width: 540px;
  text-align: left;
  color: #192D5A; }
  .account-popup > .title {
    align-items: left;
    padding-left: 50px;
    height: 60px;
    background-color: #0A46A5;
    color: #FFFFFF;
    font-size: 24px;
    text-align: left; }
  .account-popup .inner {
    padding: 10px 50px;
    height: 447px;
    overflow-y: auto;
    flex: none; }
  .account-popup > .buttons {
    margin: 0;
    padding: 16px;
    height: 80px;
    box-sizing: border-box; }
    .account-popup > .buttons button {
      padding: 10px;
      width: 120px;
      font-size: 13px;
      font-weight: bold;
      box-sizing: border-box; }
  .account-popup form {
    margin: 0 auto;
    font-size: 14px; }
    .account-popup form > div {
      text-align: left;
      align-items: left;
      margin: 12px 0; }
      .account-popup form > div.desc {
        margin-top: -8px;
        margin-left: 80px;
        font-size: 10px; }
    .account-popup form .sei-mei {
      display: flex;
      flex-direction: row; }
      .account-popup form .sei-mei .mei {
        margin-left: 10px; }
    .account-popup form label {
      flex-shrink: 0;
      margin-right: 8px;
      padding: 10px 0;
      text-align: left;
      line-height: 1em;
      font-weight: bold; }
    .account-popup form input {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      height: 40px;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      font-size: 1.0em;
      box-sizing: border-box; }
    .account-popup form select {
      padding-left: 10px;
      border: 1px solid #EEEEEE;
      border-radius: 4px;
      font-size: 1.0em;
      box-sizing: border-box;
      height: 40px; }
    .account-popup form button[type=button] {
      cursor: pointer;
      flex-grow: 0.2;
      margin: 20px auto 0 auto;
      height: 50px;
      background-color: silver;
      font-size: 1.2em; }
