.mymemos {
  background-color: #F4F5F7;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100vh - 40px);
  width: 100%; }
  .mymemos .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    box-shadow: 0 2px 4px #e2e2e4;
    font-size: 14px;
    background-color: #FFFFFF;
    height: 50px; }
    .mymemos .header .title {
      color: #192D5A;
      padding: 16px 0 18px 15px;
      width: 90px;
      box-sizing: border-box;
      text-align: left;
      font-size: 16px;
      font-weight: bold; }
    .mymemos .header button.create {
      padding: 9px 10px 7px 10px;
      font-weight: bold;
      font-size: 13px;
      margin: 0 12px 0 0; }
    .mymemos .header .Note {
      color: #8c8c8c;
      margin-right: auto; }
    .mymemos .header .search {
      display: flex;
      margin: 0 15px; }
      .mymemos .header .search > input {
        margin: 0;
        padding: 0 8px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        flex-grow: 1;
        font-size: 14px;
        box-sizing: border-box;
        width: calc(279px - 31px);
        height: 40px; }
      .mymemos .header .search button {
        margin: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        height: 40px; }
  .mymemos .search-items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #F5F7F7; }
    .mymemos .search-items div.search_date {
      box-sizing: border-box;
      text-align: left;
      margin: 0 8px 0 15px;
      padding: 0 0 15px 0; }
      .mymemos .search-items div.search_date .title {
        font-size: 12px;
        color: #192D5A;
        margin-bottom: 4px;
        padding-bottom: 4px;
        font-weight: bold; }
      .mymemos .search-items div.search_date .date-pickers {
        display: flex;
        flex-direction: row; }
      .mymemos .search-items div.search_date .hyphen {
        margin: auto;
        padding: 0 4px; }
      .mymemos .search-items div.search_date .select-label {
        padding: 13px 5px;
        font-size: 14px; }
    .mymemos .search-items div.search_meeting {
      box-sizing: border-box;
      text-align: left;
      margin: 2px 8px;
      padding: 16px 16px;
      border-left-width: 1px;
      border-left-style: dotted;
      border-left-color: #EBEBEB; }
      .mymemos .search-items div.search_meeting .title {
        font-size: 12px;
        color: #192D5A;
        font-weight: bold;
        margin: 0; }
      .mymemos .search-items div.search_meeting .radio_button {
        margin: 8px 8px; }
      .mymemos .search-items div.search_meeting .label_meeting {
        font-size: 14px;
        font-weight: 300; }
    .mymemos .search-items div.search_publiced {
      box-sizing: border-box;
      text-align: left;
      margin: 2px 8px;
      padding: 16px 16px;
      border-left-width: 1px;
      border-left-style: dotted;
      border-left-color: #EBEBEB; }
      .mymemos .search-items div.search_publiced .title {
        font-size: 12px;
        color: #192D5A;
        font-weight: bold;
        margin: 0; }
      .mymemos .search-items div.search_publiced .radio_button {
        margin: 8px 8px; }
        .mymemos .search-items div.search_publiced .radio_button input[type="radio"]:checked + .radio {
          color: #ffffff;
          background-color: #31A9EE; }
      .mymemos .search-items div.search_publiced input[type="radio"]:checked + .radio {
        color: #ffffff;
        background-color: #31A9EE; }
      .mymemos .search-items div.search_publiced input[type="radio"]:checked::after {
        color: #78341a;
        background-color: #0d3625; }
      .mymemos .search-items div.search_publiced .label_publiced {
        font-size: 14px;
        font-weight: 300; }
  .mymemos .memos {
    -webkit-flex: 1;
    flex: 1;
    overflow-y: auto;
    width: 100%; }
    .mymemos .memos .my_memo {
      text-align: left;
      border-radius: 3px;
      background-color: #FFFFFF;
      margin: 4px 16px;
      width: 95%; }
      .mymemos .memos .my_memo:hover {
        background-color: #EAEBEF; }
      .mymemos .memos .my_memo .my_memo_top {
        padding-left: 20px;
        padding-top: 16px;
        padding-right: 20px;
        height: 15px; }
      .mymemos .memos .my_memo .publiced {
        display: inline-block;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        color: #E16400;
        background: rgba(255, 170, 0, 0.3);
        border-radius: 3px;
        height: 15px;
        width: 51px;
        line-height: 15px;
        margin-right: 10px; }
      .mymemos .memos .my_memo .title {
        text-align: left;
        vertical-align: bottom;
        font-size: 12px;
        font-weight: 300;
        color: #47577B;
        padding: 0;
        width: auto; }
      .mymemos .memos .my_memo .memo {
        text-align: left;
        font-size: 13px;
        font-weight: 600;
        color: #192D5A;
        margin: 10px 20px 0 20px;
        padding: 16px 0;
        border-bottom: #EBEBEB dotted 2px;
        border-spacing: 0 10px;
        word-break: break-all;
        white-space: pre-wrap;
        width: 96%; }
      .mymemos .memos .my_memo .my_memo_Bottom {
        display: flex;
        margin: 0 20px 0 20px;
        padding-top: 8px;
        height: 27px; }
        .mymemos .memos .my_memo .my_memo_Bottom > button {
          margin: 0 8px 0 0; }
        .mymemos .memos .my_memo .my_memo_Bottom .date {
          text-align: right;
          margin-left: auto;
          vertical-align: middle;
          font-size: 12px;
          padding: 4px 0 0 0;
          font-weight: 500;
          color: #47577B;
          height: 30px;
          width: 150px; }
