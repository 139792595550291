.meeting-initial-score {
  background-color: white;
  font-size: 1em;
  color: #192D5A; }
  .meeting-initial-score .header {
    margin-bottom: 10px;
    box-shadow: 0 2px 4px #e2e2e4;
    font-size: 14px;
    background-color: white;
    flex-direction: row; }
    .meeting-initial-score .header .title {
      color: #192D5A;
      padding: 16px 0 18px 15px;
      max-width: 750px;
      box-sizing: border-box;
      text-align: left;
      font-size: 16px;
      margin-right: auto; }
  .meeting-initial-score .body {
    display: flex;
    flex-direction: column;
    -webkit-flex-direction: column; }
  .meeting-initial-score .meeting-initial-score-area {
    text-align: left;
    margin: 0 20px; }
    .meeting-initial-score .meeting-initial-score-area .meeting-initial-score-desc {
      font-size: 0.8em;
      line-height: 40px;
      border-bottom: 1px solid #E2E3E8; }
    .meeting-initial-score .meeting-initial-score-area .title {
      font-size: 1.2em;
      font-weight: bold; }
  .meeting-initial-score .buttons {
    text-align: right; }
    .meeting-initial-score .buttons .okay {
      margin: 0 0 20px 0;
      width: 100px;
      height: 40px;
      font-size: 13px;
      border-radius: 4px;
      cursor: pointer; }
  .meeting-initial-score div.scroll {
    height: calc(100vh - 100px);
    overflow: auto; }
