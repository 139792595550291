.top-usage {
  position: absolute;
  right: 0;
  top: 30px;
  width: 300px;
  flex-direction: column;
  border-radius: 4px;
  z-index: 20;
  background-color: #FFFFFF;
  color: #192D5A;
  font-size: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(25, 45, 90, 0.25), 0 0 4px rgba(25, 45, 90, 0.15);
  padding: 5px 20px 15px 20px; }
  .top-usage > div {
    width: 100%;
    box-sizing: border-box; }
  .top-usage .top-usage-item {
    display: flex;
    flex-direction: row;
    border-bottom: #E2E3E8 solid 1px;
    box-sizing: border-box;
    padding: 15px 0; }
    .top-usage .top-usage-item > div {
      box-sizing: border-box; }
    .top-usage .top-usage-item > div:first-child {
      margin-right: auto; }
    .top-usage .top-usage-item > div:nth-child(3) {
      color: #FFA000;
      font-size: 14px;
      padding-right: 6px;
      min-width: 70px;
      text-align: right; }
  .top-usage .top-usage-item:first-child {
    text-align: left;
    font-size: 16px;
    color: #192D5A; }
  .top-usage .top-usage-item:nth-child(4) {
    border: none; }
    .top-usage .top-usage-item:nth-child(4):after {
      content: "";
      display: block;
      line-height: 0;
      overflow: hidden;
      position: absolute;
      left: 33%;
      top: 76%;
      width: 61%;
      border-bottom: #E2E3E8 solid 1px; }
  .top-usage .top-usage-item:nth-child(4) > div:nth-child(2), .top-usage .top-usage-item:nth-child(5) > div:nth-child(2) {
    padding: 0 80px 0 10px; }
