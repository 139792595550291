.settings {
  font-size: 16px;
  height: 100%; }
  .settings .body {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row; }
    .settings .body .settings-left {
      box-sizing: border-box;
      width: 270px;
      font-size: 14px;
      color: #FFFFFF;
      background-color: #07347B;
      text-align: left; }
      .settings .body .settings-left .settings-header {
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-top: 20px; }
        .settings .body .settings-left .settings-header span {
          padding-left: 13px; }
      .settings .body .settings-left ul {
        display: flex;
        flex-direction: column; }
      .settings .body .settings-left li {
        height: 40px;
        cursor: pointer;
        position: relative;
        padding-left: 20px; }
        .settings .body .settings-left li.disabled {
          background-color: #192D5A;
          pointer-events: none; }
        .settings .body .settings-left li span {
          position: absolute;
          top: 50%;
          transform: translateY(-50%); }
    .settings .body .main-panel {
      -webkit-flex: 1;
      flex: 1; }
