.users {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #FFFFFF;
  color: #192D5A;
  display: flex;
  flex-direction: column; }
  .users .header {
    margin: 1px;
    background-color: #FFFFFF;
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    box-shadow: 0 2px 4px rgba(25, 45, 90, 0.2);
    width: 100%;
    box-sizing: border-box; }
    .users .header > .title {
      padding: 0 15px;
      font-size: 16px;
      box-sizing: border-box;
      word-break: break-all;
      width: 131px;
      text-align: left; }
    .users .header .operations {
      display: flex; }
      .users .header .operations button {
        padding: 0 10px;
        margin-right: 15px;
        height: 30px;
        font-size: 13px; }
  .users div.data {
    margin: 3px 1px 0 1px;
    padding: 15px 15px 0 15px;
    font-size: 13px;
    background-color: #FFFFFF;
    -webkit-flex: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    overflow-y: hidden; }
    .users div.data div.data-header {
      width: 100%;
      box-sizing: border-box;
      background-color: rgba(10, 80, 200, 0.15);
      border-radius: 2px; }
      .users div.data div.data-header table {
        width: 100%;
        box-sizing: border-box;
        table-layout: fixed;
        border-collapse: collapse;
        border-width: 0; }
        .users div.data div.data-header table thead tr {
          width: 100%;
          box-sizing: border-box;
          height: 30px; }
        .users div.data div.data-header table thead th {
          padding: 0 10px;
          font-size: 12px;
          font-weight: normal;
          height: 30px; }
    .users div.data div.data-detail {
      width: 100%;
      box-sizing: border-box;
      -webkit-flex: 1;
      flex: 1;
      overflow-y: auto; }
      .users div.data div.data-detail table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        box-sizing: border-box; }
        .users div.data div.data-detail table thead th {
          padding: 10px 10px;
          background-color: rgba(1, 81, 204, 0.15); }
          .users div.data div.data-detail table thead th:first-child {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px; }
          .users div.data div.data-detail table thead th:last-child {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px; }
        .users div.data div.data-detail table tbody tr {
          width: 100%;
          box-sizing: border-box;
          height: 60px;
          word-break: break-all;
          box-shadow: inset 0px -1px #E2E3E8; }
          .users div.data div.data-detail table tbody tr:hover td {
            background-color: #EAEBEF; }
        .users div.data div.data-detail table tbody td {
          padding: 10px 10px; }
          .users div.data div.data-detail table tbody td div.option-menu {
            position: relative;
            margin: 0 auto;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none; }
            .users div.data div.data-detail table tbody td div.option-menu div.menus {
              border-radius: 4px;
              position: absolute;
              flex-direction: column;
              top: 22px;
              left: -52px;
              width: 87px;
              height: 63px;
              z-index: 20;
              background-color: #FFFFFF;
              color: #2D3C64;
              font-size: 12px;
              text-align: center;
              box-shadow: 0 4px 12px rgba(25, 45, 90, 0.25), 0 0 4px rgba(25, 45, 90, 0.15);
              cursor: pointer; }
              .users div.data div.data-detail table tbody td div.option-menu div.menus > div {
                height: 50%;
                width: 100%;
                padding-top: 8px;
                box-sizing: border-box; }
                .users div.data div.data-detail table tbody td div.option-menu div.menus > div:hover {
                  background-color: #F4F5F7; }
              .users div.data div.data-detail table tbody td div.option-menu div.menus div.edit-group {
                border-radius: 4px 4px 0 0;
                border-bottom: 1px solid #F4F5F7; }
              .users div.data div.data-detail table tbody td div.option-menu div.menus div.delete-group {
                border-radius: 0 0 4px 4px; }
    .users div.data th.email, .users div.data td.email {
      width: 30%;
      text-align: left;
      box-sizing: border-box; }
      .users div.data th.email > div, .users div.data td.email > div {
        display: flex;
        align-items: center; }
        .users div.data th.email > div > button, .users div.data td.email > div > button {
          margin-left: 3px; }
    .users div.data th.name, .users div.data td.name {
      width: 20%;
      text-align: left; }
    .users div.data th.disp, .users div.data td.disp {
      width: 15%;
      text-align: left; }
    .users div.data th.dept, .users div.data td.dept {
      width: 20%;
      text-align: left; }
    .users div.data th.authority, .users div.data td.authority {
      width: 10%;
      text-align: left; }
    .users div.data th.functions, .users div.data td.functions {
      width: 5%;
      text-align: right; }
      .users div.data th.functions > div, .users div.data td.functions > div {
        display: flex;
        justify-content: flex-end; }

.users-upload-csv-file {
  width: 500px;
  height: 330px; }
  .users-upload-csv-file .inner {
    overflow-y: auto;
    font-size: 14px; }
  .users-upload-csv-file .attachment {
    margin: 5px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 384px;
    text-align: center; }
  .users-upload-csv-file .select-file {
    margin-bottom: 8px;
    color: #444444;
    text-align: center; }
    .users-upload-csv-file .select-file > div {
      margin-bottom: 8px; }
    .users-upload-csv-file .select-file button {
      padding: 8px 8px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 12px; }
  .users-upload-csv-file .explanation {
    margin: 20px auto;
    font-size: 12px; }
  .users-upload-csv-file .drag-and-drop {
    margin: 0 auto 20px auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 96px;
    box-sizing: border-box;
    border: 2px dashed #A1A1B2;
    color: #A1A1B2; }
  .users-upload-csv-file .upload-file {
    margin: 20px auto;
    font-size: 14px; }
  .users-upload-csv-file .drag-and-drop.enter {
    border: 2px dashed black;
    color: black; }
  .users-upload-csv-file .drag-and-drop > p {
    margin: 8px 0; }
  .users-upload-csv-file .documents {
    margin: 0;
    padding: 0;
    color: black;
    font-size: 0.8em; }
    .users-upload-csv-file .documents ul {
      flex-direction: column; }
  .users-upload-csv-file input[type="file"] {
    display: none; }
  .users-upload-csv-file > .buttons > button {
    width: 120px; }

.users-results-upload {
  width: 500px;
  height: 300px; }
  .users-results-upload .inner {
    overflow-y: auto;
    font-size: 14px; }
